import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { ModalLoadingSvg } from "@roadflex/react-svg";
import React from "react";

type ModalLoaderProps = {
  isImport?: boolean;
  loadingText?: React.ReactNode;
  completeText?: React.ReactNode;
  showLoading: boolean;
  showComplete: boolean;
};

const ModalLoader: React.FC<ModalLoaderProps> = ({
  isImport = false,
  loadingText = (
    <div className="flex flex-col items-center gap-6 text-center">
      <ModalLoadingSvg />
      {isImport ? (
        <div className="text-xl font-semibold">Preparing to import</div>
      ) : (
        <div className="text-xl font-semibold">Preparing to export</div>
      )}
      <div>
        This may take a while if there is a lot of data. Please don't close this
        window.
      </div>
    </div>
  ),
  completeText = (
    <div className="flex flex-col items-center gap-6 text-center">
      <CheckCircleIcon
        className="w-20 h-20 text-green-800"
        aria-hidden="true"
      />
      <div className="text-xl font-semibold">Complete</div>
      <div>
        {isImport ? (
          <p>Your data has been imported successfully.</p>
        ) : (
          <p>Your export should be downloading now.</p>
        )}
      </div>
    </div>
  ),
  showLoading,
  showComplete,
}) => (
  <div className="flex flex-col items-center justify-center space-y-2">
    {showLoading && (
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
        {loadingText}
      </div>
    )}
    {showComplete && (
      <div className="spinner-border text-primary" role="status">
        {completeText}
      </div>
    )}
  </div>
);

export default ModalLoader;
