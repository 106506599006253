/** @format */

import { useMutation } from "@apollo/client";
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { onboardPersonalInformationSchema } from "@roadflex/constants";
import { STORE_PERSONA_INQUIRY_ID } from "@roadflex/graphql";
import {
  useReadPersonalInformation,
  useUserUpdate,
} from "@roadflex/react-hooks";
import { PersonalInformationType, UserOnboardType } from "@roadflex/types";
import { phoneFormatter, ssnFormatter } from "@roadflex/web-lib";
import { useEffect, useState } from "react";
import { Toast } from "../toast-message/toast";
import PersonalInformationUI from "./personal-information.ui";

interface PersonalInformationProps {
  readCurrentUserOnboard: UserOnboardType;
  nextStep: (jumpStepBy?: number) => void;
  isSubmittingOnboardStatus: boolean;
  personalInfo: PersonalInformationType;
  onPreviousStep: () => void;
}

const PersonalInformation = ({
  readCurrentUserOnboard,
  nextStep,
  isSubmittingOnboardStatus,
  personalInfo,
  onPreviousStep,
}: PersonalInformationProps) => {
  const [isVerifyEnabled, setVerifyEnabled] = useState(false);

  const {
    email = "",
    id = "",
    personaInquiryId = "",
    personaInquirySessionToken = "",
    fuelCard = { customerType: "" },
  } = readCurrentUserOnboard;

  const customerType = fuelCard?.customerType || "";
  useEffect(() => {
    if (
      customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE ||
      customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE
    ) {
      setVerifyEnabled(true);
    }
  }, [customerType]);

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    ...rest
  } = useUserUpdate({
    initialValues: {
      id: personalInfo?.id || "",
      firstName: personalInfo?.firstName || "",
      lastName: personalInfo?.lastName || "",
      phoneNumber: phoneFormatter(personalInfo?.phoneNumber || ""),
      ssn: ssnFormatter(personalInfo?.ssn || ""),
      personaInquiryId,
      personaFields: "",
      fleetLicense: personalInfo?.fleetLicense || "",
      fleetLicenseBack: personalInfo?.fleetLicenseBack || "",
      dateOfBirth: personalInfo?.dateOfBirth || "",
      executiveJobTitle: personalInfo?.executiveJobTitle || "OWNER",
    },
    validationSchema: onboardPersonalInformationSchema,
    onSubmit(res, err) {
      if (!err) {
        nextStep();
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
    },
  });

  const [storePersonaInquiryId] = useMutation(STORE_PERSONA_INQUIRY_ID);

  const handleStorePersonaInquiryId = (personaInquiryId1: string) => {
    storePersonaInquiryId({
      variables: {
        data: {
          inquiryId: personaInquiryId1,
        },
      },
    }).catch((err) => {
      // console.log(err);
      Toast({ type: "error", message: err.message });
    });
  };

  return (
    <PersonalInformationUI
      {...{
        isVerifyEnabled,
        setVerifyEnabled,
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        handleBlur,
        errors,
        touched,
        ...rest,
        isSubmittingOnboardStatus,
        id,
        email,
        personaInquiryId,
        personaInquirySessionToken,
        handleStorePersonaInquiryId,
        customerType,
        personalInfo,
        onPreviousStep,
      }}
    />
  );
};

function FetchInfo(props: {
  readCurrentUserOnboard: UserOnboardType;
  nextStep: (jumpStepBy?: number) => void;
  isSubmittingOnboardStatus: boolean;
  onPreviousStep: () => void;
}) {
  const { data, loading } = useReadPersonalInformation();
  if (loading) {
    return <div className="my-6 text-center text-white">Loading...</div>;
  }

  const { readPersonalInformation } = data;

  return (
    <PersonalInformation
      {...{ ...props, personalInfo: readPersonalInformation?.personalInfo }}
    />
  );
}

export default FetchInfo;
