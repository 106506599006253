/** @format */
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { BankVerificationValues, PlaidType } from "@roadflex/types";
import classNames from "classnames";
import { FormikProps } from "formik";
import getConfig from "next/config";
import { MutableRefObject, useState } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import ErrorMessage from "../error-message/errorMessage";
import PlaidLink from "../fuelcard-components/plaidAlt";
const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

interface BankVerificationUIProps extends FormikProps<BankVerificationValues> {
  handleFiles: (files: FileList) => void;
  handleRemoveFile: (file: File) => void;
  isSubmittingOnboardStatus: boolean;
  linkToken: string;
  institution: MutableRefObject<string>;
  publicToken: MutableRefObject<string>;
  plaidData: PlaidType[];
  uploadedStatements: string[];
  customerType: string;
  onPreviousStep: () => void;
}
const BankVerificationUI = ({
  values,
  handleFiles,
  handleRemoveFile,
  handleSubmit,
  isSubmitting,
  isSubmittingOnboardStatus,
  setFieldValue,
  handleBlur,
  errors,
  linkToken,
  institution,
  publicToken,
  status,
  setStatus,
  plaidData,
  customerType,
  uploadedStatements,
  onPreviousStep,
}: BankVerificationUIProps) => {
  const [showStatementUI, setShowStatementUI] = useState(false);
  return (
    <div className="text-white">
      <form onSubmit={handleSubmit} className="flex flex-col w-full text-white">
        {customerType.includes("CREDIT") && (
          <div>
            <div className="mb-1 text-xl font-semibold text-center text-white md:mb-4 md:text-4xl">
              {showStatementUI
                ? `Upload Business Bank Statements`
                : `Connect Business Bank Account`}
            </div>
            <div className="mb-4 text-xs text-center text-white md:mb-8 md:text-sm">
              {showStatementUI ? (
                <span>
                  Upload bank statements for your{" "}
                  <span className="font-bold">
                    primary business bank account
                  </span>{" "}
                  with a good balance, showing business revenue from the most
                  recent three months
                </span>
              ) : (
                `We assess your revenue history to match you with the best fuel card option for your business.`
              )}
            </div>
            <div className="space-y-4 text-xs md:text-sm">
              <div className="">
                For credit users, we need your proof of revenue to approve your
                application and assign you a credit limit.
              </div>
              <div className="">
                Get the best credit limit by lining a business bank account with
                strongest revenue deposits. You can use another account to pay
                later.
              </div>
            </div>
            <div className="my-4 h-[1px] w-full bg-orange-500 opacity-50"></div>
            <div className={classNames(!showStatementUI ? "block" : "hidden ")}>
              <div className="space-y-4 text-white">
                <div className="font-semibold">
                  Option 1: Link Business Bank Account
                </div>
                <PlaidLink
                  linkToken={linkToken}
                  institution={institution}
                  plaidData={plaidData}
                  publicToken={publicToken}
                  setStatus={setStatus}
                  setFieldValue={setFieldValue}
                />
                <div className="flex flex-row">
                  <div className="relative mr-5">
                    <svg
                      width="24"
                      height="32"
                      viewBox="0 0 24 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.45053 14.1161H20.8048C21.1895 14.1161 21.5584 14.2689 21.8304 14.5409C22.1025 14.813 22.2553 15.1819 22.2553 15.5666V26.5317C22.2553 27.1189 22.1395 27.7005 21.9146 28.243C21.6897 28.7855 21.36 29.2784 20.9444 29.6934C20.5289 30.1084 20.0356 30.4374 19.4928 30.6616C18.95 30.8858 18.3683 31.0008 17.781 31H5.46834C4.88155 31 4.3005 30.8844 3.75838 30.6599C3.21626 30.4353 2.72367 30.1062 2.30875 29.6913C1.89382 29.2763 1.56469 28.7837 1.34013 28.2416C1.11558 27.6995 1 27.1184 1 26.5317V15.5666C1 15.1819 1.15282 14.813 1.42485 14.5409C1.69688 14.2689 2.06582 14.1161 2.45053 14.1161V14.1161Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                      />
                      <path
                        d="M3.91504 13.8291V8.71768C3.91504 6.67082 4.72806 4.7078 6.1754 3.26046C7.62275 1.81311 9.58586 1 11.6327 1C13.6796 1 15.6426 1.81311 17.0899 3.26046C18.5373 4.7078 19.3504 6.67082 19.3504 8.71768V13.8351"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                      />
                      <path
                        d="M14.48 19.9973C14.4805 19.4846 14.3428 18.9813 14.0813 18.5403C13.8198 18.0993 13.4442 17.737 12.9941 17.4914C12.5441 17.2459 12.0361 17.1262 11.5238 17.1451C11.0114 17.1639 10.5136 17.3205 10.0828 17.5984C9.65196 17.8763 9.304 18.2652 9.07558 18.7242C8.84716 19.1832 8.74664 19.6953 8.78474 20.2066C8.82284 20.7179 8.99809 21.2094 9.29202 21.6295C9.58595 22.0496 9.98773 22.3827 10.455 22.5936V26.7948C10.4555 27.1059 10.5793 27.4041 10.7993 27.624C11.0192 27.844 11.3175 27.9678 11.6285 27.9683V27.9683C11.9398 27.9683 12.2382 27.8447 12.4582 27.6246C12.6783 27.4045 12.8019 27.1061 12.8019 26.7948V22.5936C13.302 22.3684 13.7263 22.0036 14.024 21.543C14.3217 21.0825 14.48 20.5457 14.48 19.9973V19.9973Z"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <div className="text-xs">
                      We partner with Plaid for safe and secure connection to
                      your bank.
                    </div>
                    <div className="text-xs">Approval in 2 business days.</div>
                  </div>
                </div>
              </div>
              <div className="my-4 h-[1px] w-full bg-orange-500 opacity-50"></div>

              <div className="space-y-4 text-white">
                <div className="font-semibold">
                  Option 2: Upload Bank Statements
                </div>
                <div className="text-sm text-white ">
                  <div className="font-semibold">
                    Can&apos;t find your bank?
                  </div>
                  <div
                    className="hover:underline hover:cursor-pointer"
                    onClick={() => {
                      setShowStatementUI(true);
                    }}
                  >
                    Upload 3 months of business bank statements
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                showStatementUI ? "block" : "hidden ",
                "space-y-4",
              )}
            >
              <ul className="ml-4 list-disc">
                <li>
                  Only PDF files are accepted{" "}
                  <span>(Maximum size: 3MB/ file, 3 files)</span>
                </li>
                <li>
                  Must be downloaded directly from your digital bank, or emailed
                  from your bank (no scanned documents)
                </li>
                <li>
                  Must include transactions (payments and expenses) within each
                  of the past 3 months
                </li>
              </ul>
              {uploadedStatements?.length > 0 && !values.docsReupload ? (
                <div className="w-full my-10 text-sm text-center text-white md:text-base">
                  <div className="flex flex-col sm:flex-row">
                    Uploaded Statements :{" "}
                    {uploadedStatements.map((statement, idx) => (
                      <a
                        key={idx}
                        href={`${NEXT_PUBLIC_API_URL}/api/files/${statement}`}
                        rel="noreferrer"
                        target="_blank"
                        className="ml-2 underline underline-offset-2"
                      >
                        {`Statement ${idx + 1}`}
                      </a>
                    ))}
                  </div>
                  <div className="flex items-center justify-center mt-10">
                    If you want to upload new files, click{" "}
                    <Button
                      type={ButtonType.Button}
                      variant={ButtonVariant.Transparent}
                      size={ButtonSize.Normal}
                      className="!p-0 ml-1  underline underline-offset-1"
                      onClick={() => {
                        setFieldValue("docsReupload", true);
                      }}
                    >
                      here
                    </Button>
                    .
                  </div>
                </div>
              ) : (
                <>
                  <div className="text-xs">
                    Click the area below to upload files from your device
                  </div>
                  <div className="flex items-center justify-center w-full mx-auto text-gray-500 bg-white rounded-lg cursor-pointer">
                    <label
                      htmlFor="statements"
                      className="flex flex-col w-full h-32 cursor-pointer"
                    >
                      <div className="flex flex-col items-center justify-center pt-7">
                        <DocumentMagnifyingGlassIcon
                          className="w-10 h-10 text-gray-500"
                          aria-hidden="true"
                        />
                        Browse
                      </div>
                      <input
                        className="hidden"
                        type="file"
                        id="statements"
                        name="statements"
                        onChange={(event) => {
                          handleFiles(event?.target?.files as FileList);
                          setStatus({ error: null });
                        }}
                        onBlur={handleBlur}
                        multiple
                        accept=".pdf"
                      />
                    </label>
                  </div>
                </>
              )}
              {values.statements.length > 0 && (
                <div className="flex flex-col text-white">
                  {(values.statements as File[]).map(
                    (statement, index: number) => (
                      <div
                        key={statement.name + index}
                        className="flex flex-row items-center"
                      >
                        <div className="mr-3">
                          {statement.name && (
                            <span className="my-3">{statement.name}</span>
                          )}
                          {!statement.name && (
                            <span className="my-3">{`Statement ${
                              index + 1
                            }`}</span>
                          )}
                        </div>
                        <button
                          type="button"
                          onClick={() => handleRemoveFile(statement as File)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </div>
                    ),
                  )}
                </div>
              )}
              <div className="text-xs text-center">
                Approval in 5 business days
              </div>
              <div className="text-sm text-white ">
                <div
                  className="hover:underline hover:cursor-pointer"
                  onClick={() => {
                    setShowStatementUI(false);
                  }}
                >
                  Connect bank account instead?
                </div>
              </div>
            </div>
            <div className="mt-4">
              {errors.statements && (
                <div className="text-xs text-red-500">
                  {String(errors.statements)}
                </div>
              )}
            </div>

            <div className="mt-4 text-sm text-center">
              Bank Information is required to qualify for a credit line
            </div>
            <div className="text-sm text-center">
              Apply for a prepaid RoadFlex card instead if you don't need credit
            </div>
            <div className="transition-all duration-150 ease-linear">
              <div className="col-span-6 sm:col-span-6">
                <div className="mb-2"></div>
              </div>
              {status?.error && <ErrorMessage error={status.error} />}
            </div>
          </div>
        )}
        {customerType.includes("PREPAID") && (
          <div>
            <div className="my-6 text-xl font-semibold text-center">
              Your Finances
            </div>
            <div className="space-y-4 text-sm text-center md:text-base">
              <div className="">
                You are applying for the PREPAID fuel card. Continue to review
                your application.
              </div>
            </div>
          </div>
        )}
        <div className="mt-6 text-right">
          <Button
            type={ButtonType.Submit}
            variant={ButtonVariant.Orange}
            className="flex flex-row items-center justify-center w-full"
            disabled={isSubmitting || isSubmittingOnboardStatus}
            loading={isSubmitting || isSubmittingOnboardStatus}
          >
            {isSubmitting || isSubmittingOnboardStatus
              ? "Loading..."
              : "Review Application"}
          </Button>
        </div>
        <div>
          <div className="flex items-center justify-center mt-1 text-sm text-center text-white">
            <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
            We securely store and encrypt your personal information
          </div>
          <Button
            type={ButtonType.Button}
            variant={ButtonVariant.Transparent}
            className="pl-0 text-white"
            size={ButtonSize.Small}
            onClick={onPreviousStep}
          >
            &#60;&#60; Go back
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BankVerificationUI;
