/** @format */

import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { appCardOrderSchema } from "@roadflex/constants";
import { CustomizedSettingType, SettingsType } from "@roadflex/types";
import { dollarFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { Select } from "../../dropdowns";
import { CheckboxInput, RadioInput, TextInput } from "../../inputs";

interface CardOrderUIProps {
  settings: SettingsType;
  handleCardsOrder: (
    noOfCards: number,
    deliveryType: string,
    requireSignature: boolean,
    cardType: string,
    cardProfileId: string,
  ) => void;
  submittingRequest: boolean;
  orderedCardsLength: number;
  userCustomisedSettings: CustomizedSettingType;
}

export const CardOrderUI = ({
  settings,
  handleCardsOrder,
  submittingRequest,
  orderedCardsLength = 0,
  userCustomisedSettings,
}: CardOrderUIProps) => {
  const router = useRouter();

  const [cards, setCards] = useState(1);
  const [delivery, setDelivery] = useState("STANDARD");
  const [addSignature, setAddSignature] = useState(false);

  const [print, setPrint] = useState(0);
  const [ship, setShip] = useState(0);
  const [signature, setSignature] = useState(0);
  const [cardOrdering, setCardOrdering] = useState(0);

  const isEnterpriseUser: boolean =
    settings?.customerType?.includes("ENTERPRISE");

  const printingFee = isEnterpriseUser
    ? userCustomisedSettings?.printingFee / 100
    : 3;
  const shippingFee = isEnterpriseUser
    ? userCustomisedSettings?.shippingFee / 100
    : 27;
  const bulkShippingFee = isEnterpriseUser
    ? userCustomisedSettings?.shippingFee / 100
    : 45;
  const signatureFee = isEnterpriseUser
    ? userCustomisedSettings?.requireSignatureFee / 100
    : 3;
  const virtualOrderFee = isEnterpriseUser
    ? userCustomisedSettings?.virtualOrderFee / 100
    : 2;

  const backToCardsPanel = () => {
    const { query } = router;
    const { type } = query;

    if (type === "virtual") {
      router.push("/virtual-cards");
    } else {
      router.push("/cards");
    }
  };
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    isSubmitting,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: {
      noOfCards: 1,
      deliveryType: "STANDARD",
      requireSignature: false,
      cardType: "PHYSICAL",
      cardProfileId: "",
    },
    validationSchema: appCardOrderSchema(
      orderedCardsLength,
      settings?.numberOfCards,
    ),
    onSubmit: async (value) => {
      handleCardsOrder(
        value.noOfCards,
        value.deliveryType,
        value.requireSignature,
        value.cardType,
        value.cardProfileId,
      );
    },
  });

  useEffect(() => {
    const { query } = router;
    const { type } = query;

    if (type === "virtual") {
      setFieldValue("cardType", "VIRTUAL");
    } else {
      setFieldValue("cardType", "PHYSICAL");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const calculateFees = () => {
    if (delivery === "PRIORITY") {
      setPrint(cards * printingFee);
    } else {
      setPrint(cards * 0);
    }
    if (delivery === "PRIORITY") {
      if (addSignature) {
        //Only for priority users
        if (settings.numberOfCards >= 5) {
          if (cards <= 1) {
            setSignature(cards * signatureFee);
          } else {
            setSignature(signatureFee);
          }
        } else {
          setSignature(cards * signatureFee);
        }
      } else {
        setSignature(0);
      }
    } else {
      setSignature(0);
    }

    if (delivery === "PRIORITY") {
      if (settings.numberOfCards >= 5) {
        if (cards <= 1) {
          setShip(cards * shippingFee);
        } else {
          setShip(bulkShippingFee);
        }
      } else {
        setShip(cards * shippingFee);
      }
    } else {
      setShip(cards * 0);
    }

    if (values.cardType === "VIRTUAL") {
      setCardOrdering(cards * virtualOrderFee);
    } else {
      setCardOrdering(0);
    }
  };

  useEffect(() => {
    calculateFees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery, cards, addSignature, values.cardType]);

  const cardTypes = [
    {
      id: 1,
      title: "Physical Card",
      disabled: false,
      value: "PHYSICAL",
    },
    {
      id: 2,
      title: "Virtual Card",
      disabled: false,
      value: "VIRTUAL",
    },
  ];

  return (
    <div className="flex-col flex flex-1 md:px-4 md:pb-4 mt-0 m-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-row items-center">
              <div className="">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.GrayOutline}
                  onClick={backToCardsPanel}
                  className="pl-0 ml-[-6px] border-0"
                >
                  <span className="flex items-center h-5">
                    <ChevronRightIcon
                      className={
                        "-rotate-180 h-5 w-5 transform ease-in duration-300"
                      }
                      aria-hidden="true"
                    />
                  </span>
                  Back
                </Button>
              </div>
              <div className="ml-4 text-sm font-medium md:text-lg ">
                Card Order Form
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 mt-5 bg-white rounded-md shadow md:p-8 md:mt-0">
          <div className="mb-2">
            <div className="mb-2 font-semibold">Physical cards</div>
            <div>
              RoadFlex does not charge a card production or shipping fee for
              cards shipped via standard shipping. For priority shipping, you
              must have sufficient balance in your RoadFlex account. The fees
              are deducted when the cards are ordered.
            </div>
            <div className="mt-4 mb-2 font-semibold md:mt-8">Virtual cards</div>
            <div>
              Virtual cards have the same capabilities as physical cards. For
              security reasons, do not share your virtual card details. Please
              ensure there is sufficient balance in your RoadFlex account when
              you order a virtual card.
            </div>
            <div className="my-4 md:my-8">
              Your account is authorized to order a maximum of{" "}
              {settings.numberOfCards} virtual and physical cards. If you have
              any questions, please reach out to&nbsp;
              <a href="mailto:support@roadflex.com" className="text-gray-700">
                support@roadflex.com
              </a>
              .
            </div>
          </div>
          <div className="grid grid-cols-7 gap-4 md:gap-6 md:py-4">
            <div className="col-span-7 md:col-span-4">
              <form className="w-full">
                <div className="flex flex-col gap-4 md:gap-8 md:my-4">
                  <TextInput
                    label="How many cards would you like?"
                    type="number"
                    min={1}
                    name="noOfCards"
                    id="noOfCards"
                    value={values.noOfCards}
                    onChange={(e) => {
                      handleChange(e);
                      setCards(Number(e.target.value));
                    }}
                    onBlur={handleBlur}
                    placeholder="Number of cards"
                    aria-describedby="email-description"
                    touched={touched.noOfCards}
                    error={touched.noOfCards && Boolean(errors.noOfCards)}
                    errorMessage={errors.noOfCards}
                  ></TextInput>

                  <RadioGroup
                    className=""
                    value={values.cardType}
                    onChange={(e: string) => setFieldValue("cardType", e)}
                  >
                    <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-3">
                      {cardTypes.map((cardType) => (
                        <RadioGroup.Option
                          disabled={cardType.disabled}
                          key={cardType.id}
                          value={cardType.value}
                          className={({ disabled }) =>
                            classNames(
                              disabled ? "opacity-80" : "",
                              "relative flex cursor-pointer rounded-lg border border-brown-400 bg-white p-4 shadow-sm focus:outline-none",
                            )
                          }
                        >
                          <>
                            <span className="flex flex-1">
                              <span className="flex flex-col">
                                <RadioGroup.Label
                                  as="span"
                                  className="block text-sm font-medium text-brown-700"
                                >
                                  {cardType.title}
                                </RadioGroup.Label>
                              </span>
                            </span>
                            {cardType.value === values.cardType ? (
                              <CheckCircleIcon
                                className="w-6 h-6 text-brown-700"
                                aria-hidden="true"
                              />
                            ) : null}
                            <span
                              className={classNames(
                                cardType.value === values.cardType
                                  ? "border-brown-500 border"
                                  : "border-transparent",
                                "pointer-events-none absolute -inset-px rounded-lg",
                              )}
                              aria-hidden="true"
                            />
                          </>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                  {userCustomisedSettings?.cardProfiles.length > 0 && (
                    <div>
                      <div className="w-full">
                        <Select
                          label="Card Profile"
                          onChange={(e) => {
                            setFieldValue("cardProfileId", e.value);
                          }}
                          value={values.cardProfileId}
                          name="cardProfileId"
                          options={userCustomisedSettings?.cardProfiles.map(
                            (cardProfile, i) => {
                              return {
                                label: cardProfile.name,
                                value: cardProfile.cardProfileId,
                              };
                            },
                          )}
                          optionLabel="label"
                          optionValue="value"
                          placeholder="-- Select a Card Profile --"
                        ></Select>
                      </div>
                    </div>
                  )}
                  {values.cardType === "PHYSICAL" && (
                    <div className="flex flex-col gap-8">
                      <div className="">
                        <div className="mb-2">
                          <label>Shipping Methods</label>
                        </div>
                        <div className="">
                          <RadioInput
                            type="radio"
                            name="deliveryType"
                            id="deliveryType"
                            value="STANDARD"
                            className="!rounded"
                            onChange={(e) => {
                              handleChange(e);
                              setDelivery(e.target.value);
                              if (e.target.value === "STANDARD") {
                                setFieldValue("requireSignature", false);
                                setAddSignature(false);
                              }
                            }}
                            checked={values.deliveryType === "STANDARD"}
                            onBlur={handleBlur}
                            placeholder=""
                            label={
                              <label className="" htmlFor="deliveryType">
                                Standard{" "}
                                <span className="ml-2 italic text-green-800">
                                  FREE
                                </span>
                              </label>
                            }
                          ></RadioInput>

                          <div className="text-sm italic">
                            Arrives within 5-8 business days. Each card will be
                            delivered in a separate envelope, affixed to a
                            carrier.
                          </div>
                          {/* </label> */}
                        </div>

                        <div className="mt-4">
                          <RadioInput
                            type="radio"
                            name="deliveryType"
                            id="deliveryType"
                            value="PRIORITY"
                            className="!rounded"
                            onChange={(e) => {
                              handleChange(e);
                              setDelivery(e.target.value);
                            }}
                            checked={values.deliveryType === "PRIORITY"}
                            onBlur={handleBlur}
                            placeholder=""
                            label={
                              <label htmlFor="deliveryType">Priority</label>
                            }
                          ></RadioInput>

                          {settings?.numberOfCards &&
                            settings?.numberOfCards > 5 && (
                              <div className="text-sm italic">
                                <span>
                                  Arrives within 2-3 business days. If 2 or more
                                  cards are ordered, the cards will be delivered
                                  in a single envelope, affixed to a carrier.
                                </span>
                              </div>
                            )}
                          {settings?.numberOfCards <= 5 && (
                            <div className="text-sm italic">
                              <span>
                                Arrives within 2-3 business days. Each card will
                                be delivered in a separate envelope, affixed to
                                a carrier.
                              </span>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="">
                        <div className="mb-2">
                          <label>Additional Requirements</label>
                        </div>
                        <CheckboxInput
                          type="checkbox"
                          name="requireSignature"
                          id="requireSignature"
                          disabled={values.deliveryType === "STANDARD"}
                          checked={values.requireSignature}
                          onChange={(e) => {
                            handleChange(e);
                            setAddSignature(!values.requireSignature);
                          }}
                          onBlur={handleBlur}
                          placeholder=""
                          label={"Require Signature"}
                        ></CheckboxInput>

                        <div className="text-sm italic">
                          <span>
                            Signature required for delivery. Available only for
                            Priority shipping.
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
            <div className="col-span-7 md:p-8 border-brown-500 items md:px-8 md:border-l-2 md:col-span-3">
              <div className="pt-4 pb-2 mb-2 border-b-2 md:py-2 border-brown-500">
                Order Summary
              </div>
              <div className="py-4">
                <CheckboxInput
                  type="checkbox"
                  name="bulkShipping"
                  id="bulkShipping"
                  disabled={true}
                  checked={
                    settings?.numberOfCards >= 5 &&
                    values?.noOfCards > 1 &&
                    values?.deliveryType === "PRIORITY"
                  }
                  onBlur={handleBlur}
                  label={"Bulk Shipping: Your cards will be shipped together."}
                ></CheckboxInput>
              </div>
              <div className="grid grid-cols-4 gap-6 py-4">
                <div className="col-span-3"># cards:</div>
                <div className="col-span-1 text-brown-500">
                  {values.noOfCards}
                </div>
                <div className="col-span-3">Printing Fee:</div>
                <div className="col-span-1 text-brown-500">
                  <div>{dollarFormatter(print)}</div>
                </div>
                <div className="col-span-3">Shipping Fee:</div>
                <div className="col-span-1 text-brown-500">
                  <div>{dollarFormatter(ship)}</div>
                </div>
                <div className="col-span-3">Require Signature Fee:</div>
                <div className="col-span-1 text-brown-500">
                  <div>{dollarFormatter(signature)}</div>
                </div>
                <div className="col-span-3">Card Ordering Fee (Virtual):</div>
                <div className="col-span-1 text-brown-500">
                  <div>{dollarFormatter(cardOrdering)}</div>
                </div>
              </div>
              <div className="py-2 border-t-2 border-brown-500"></div>
              <div className="grid grid-cols-4 gap-6 md:py-4">
                <div className="col-span-3">Total:</div>
                <div className="col-span-1 text-brown-500">
                  <div>
                    {dollarFormatter(signature + ship + print + cardOrdering)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4 md:mt-8">
            <Button
              variant={ButtonVariant.AppOrange}
              disabled={isSubmitting || submittingRequest}
              loading={isSubmitting || submittingRequest}
              size={ButtonSize.AppSize}
              className="!mr-0"
              onClick={() => {
                handleSubmit();
              }}
            >
              Order
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
