/** @format */
import { useMutation } from "@apollo/client";
import { MinusCircleIcon } from "@heroicons/react/24/outline";
import {
  ENUM_CUSTOMER_TYPE,
  ENUM_NOTIFICATION_CATEGORY,
  ENUM_NOTIFICATION_CHANNEL,
  ENUM_NOTIFICATION_NAME,
} from "@prisma/client";
import {
  balanceLabels,
  CATEGORIES_MASTER,
  flaggedTransactionLabels,
  fuelTypesNotificationLabels,
  labels,
  transactionAboveLabels,
} from "@roadflex/constants";
import {
  READ_USER_NOTIFICATIONS,
  UPDATE_USER_NOTIFICATIONS,
} from "@roadflex/graphql";
import {
  ChildFeatureType,
  NotificationsTypeExtended,
  UpdateNotificationsType,
} from "@roadflex/types";
import { humanize } from "@roadflex/utils";
import classNames from "classnames";
import { InputSwitch, InputSwitchChangeParams } from "primereact/inputswitch";
import { MultiSelectChangeParams } from "primereact/multiselect";
import { Button, ButtonSize, ButtonVariant } from "../../../buttons";
import { CustomMultiSelect } from "../../../dropdowns";
import { CheckboxInput, RadioInput } from "../../../inputs";
import { FuelCardComponentPermissionGuard } from "../../../permission-guard";
import { Toast } from "../../../toast-message/toast";

type labelType = {
  label: string;
  value: string;
  stringValue: string;
};

interface PersonalNotificationsUIProp {
  notifications: NotificationsTypeExtended[];
  updating: boolean;
  setUpdating: (val: boolean) => void;
  setNotifications: (val: NotificationsTypeExtended[]) => void;
  declineOptions: Map<string, boolean>;
  setdeclineOptions: (val: Map<string, boolean>) => void;
  transactionFlagOptions: Map<string, boolean>;
  setTransactionFlagOptions: (val: Map<string, boolean>) => void;
  fuelTypeNotificationOptions: Map<string, boolean>;
  setFuelTypeNotificationOptions: (val: Map<string, boolean>) => void;
  customerType: ENUM_CUSTOMER_TYPE;
  balanceValue: string;
  setBalanceValue: (val: string) => void;
  transactionAboveAmount: string;
  setTransactionAboveAmount: (val: string) => void;
  childFeatures: ChildFeatureType[];
  selectedTransactionApprovedCategory: string[];
  setSelectedTransactionApprovedCategory: (val: string[]) => void;
}
const PersonalNotificationsUI = ({
  notifications,
  updating,
  setUpdating,
  setNotifications,
  declineOptions,
  setdeclineOptions,
  transactionFlagOptions,
  setTransactionFlagOptions,
  fuelTypeNotificationOptions,
  setFuelTypeNotificationOptions,
  customerType,
  balanceValue,
  setBalanceValue,
  transactionAboveAmount,
  setTransactionAboveAmount,
  selectedTransactionApprovedCategory,
  setSelectedTransactionApprovedCategory,
  childFeatures,
}: PersonalNotificationsUIProp) => {
  const [updateUserNotifications] = useMutation<{
    updateUserNotifications: {
      message: string;
      code: string;
    };
  }>(UPDATE_USER_NOTIFICATIONS, {
    refetchQueries: [READ_USER_NOTIFICATIONS],
  });
  const BillingAndPayment: NotificationsTypeExtended[] = notifications
    ?.filter(
      (key) => key.category === ENUM_NOTIFICATION_CATEGORY.BILLING_AND_PAYMENT,
    )
    .sort((a, b) => (a.rank > b.rank ? 1 : -1));
  const Transaction: NotificationsTypeExtended[] = notifications
    ?.filter((key) => key.category === ENUM_NOTIFICATION_CATEGORY.TRANSACTION)
    .sort((a, b) => (a.rank > b.rank ? 1 : -1));
  const Account: NotificationsTypeExtended[] = notifications
    ?.filter((key) => key.category === ENUM_NOTIFICATION_CATEGORY.ACCOUNT)
    .sort((a, b) => (a.rank > b.rank ? 1 : -1));
  const Analysis: NotificationsTypeExtended[] = notifications
    ?.filter((key) => key.category === ENUM_NOTIFICATION_CATEGORY.ANALYSIS)
    .sort((a, b) => (a.rank > b.rank ? 1 : -1));

  const access = {
    BillingNotificationAccess:
      childFeatures
        .find(
          (item) =>
            item.name === "NOTIFICATION_PAGE_PERSONAL_NOTIFICATION_SETTING",
        )
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "NOTIFICATION_PAGE_PERSONAL_NOTIFICATION_SETTING_BILLING_PAYMENT",
        ) !== undefined,
    TransactionNotificationAccess:
      childFeatures
        .find(
          (item) =>
            item.name === "NOTIFICATION_PAGE_PERSONAL_NOTIFICATION_SETTING",
        )
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "NOTIFICATION_PAGE_PERSONAL_NOTIFICATION_SETTING_TRANSACTION_NOTIFICATION",
        ) !== undefined,
    TransactionApprovedAccess:
      childFeatures
        .find(
          (item) =>
            item.name === "NOTIFICATION_PAGE_PERSONAL_NOTIFICATION_SETTING",
        )
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "NOTIFICATION_PAGE_PERSONAL_NOTIFICATION_SETTING_TRANSACTION_APPROVED",
        ) !== undefined,
    AccountNotificationAccess:
      childFeatures
        .find(
          (item) =>
            item.name === "NOTIFICATION_PAGE_PERSONAL_NOTIFICATION_SETTING",
        )
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "NOTIFICATION_PAGE_PERSONAL_NOTIFICATION_SETTING_ACCOUNT_NOTIFICATION",
        ) !== undefined,
    AnalysisNotificationAccess:
      childFeatures
        .find(
          (item) =>
            item.name === "NOTIFICATION_PAGE_PERSONAL_NOTIFICATION_SETTING",
        )
        ?.grandchildren?.find(
          (item) =>
            item.name ===
            "NOTIFICATION_PAGE_PERSONAL_NOTIFICATION_SETTING_ANALYSIS",
        ) !== undefined,
  };

  const handleChange = (e: InputSwitchChangeParams) => {
    const { value, name } = e.target;
    const nameArray = name.split(" ");
    const dataNew: UpdateNotificationsType[] = [];
    const dataNewNotifications: NotificationsTypeExtended[] = [];
    notifications.forEach((element) => {
      const newObject = {
        name: element.name,
        communicationMode: element.communicationMode,
        optionValues: element.optionValues,
      };
      const newNotificationsObject = element;
      let emailVariable = element.email;
      let smsVariable = element.sms;
      if (element.name === nameArray[0]) {
        if (nameArray[1] === "Sms") {
          smsVariable = value;
          newNotificationsObject.sms = value;
        }
        if (nameArray[1] === "Email") {
          emailVariable = value;
          newNotificationsObject.email = value;
        }
        if (emailVariable === true && smsVariable === true) {
          newObject.communicationMode = ENUM_NOTIFICATION_CHANNEL.EMAIL_AND_SMS;
          newNotificationsObject.communicationMode =
            ENUM_NOTIFICATION_CHANNEL.EMAIL_AND_SMS;
        } else if (emailVariable === true && smsVariable === false) {
          newObject.communicationMode = ENUM_NOTIFICATION_CHANNEL.EMAIL;
          newNotificationsObject.communicationMode =
            ENUM_NOTIFICATION_CHANNEL.EMAIL;
        } else if (emailVariable === false && smsVariable === true) {
          newObject.communicationMode = ENUM_NOTIFICATION_CHANNEL.SMS;
          newNotificationsObject.communicationMode =
            ENUM_NOTIFICATION_CHANNEL.SMS;
        } else {
          newObject.communicationMode = ENUM_NOTIFICATION_CHANNEL.NONE;
          newNotificationsObject.communicationMode =
            ENUM_NOTIFICATION_CHANNEL.NONE;
        }
      }
      dataNew.push(newObject);
      dataNewNotifications.push(newNotificationsObject);
    });
    setNotifications(dataNewNotifications);
  };

  const saveVariable = (mode: string) => {
    setUpdating(true);
    const dataNew: UpdateNotificationsType[] = [];
    const dataNewNotifications: NotificationsTypeExtended[] = [];

    notifications.forEach((element) => {
      const newObject = {
        name: element.name,
        communicationMode: element.communicationMode,
        optionValues: element.optionValues,
        variables: element.variables,
      };
      const newNotificationsObject = element;
      if (
        (mode === "declineOption" || mode === "all") &&
        element.name === ENUM_NOTIFICATION_NAME.TRANSACTION_DECLINED
      ) {
        const newArray: string[] = [];
        declineOptions.forEach((value: boolean, key: string) => {
          if (value) {
            newArray.push(key);
          }
        });
        newObject.optionValues = newArray;
        newNotificationsObject.optionValues = newArray;
      }
      if (
        (mode === "transactionFlag" || mode === "all") &&
        element.name === ENUM_NOTIFICATION_NAME.TRANSACTION_FLAGGED
      ) {
        const newArray: string[] = [];
        transactionFlagOptions.forEach((value: boolean, key: string) => {
          if (value) {
            newArray.push(key);
          }
        });
        newObject.optionValues = newArray;
        newNotificationsObject.optionValues = newArray;
      }
      if (
        (mode === "fuelTypeNotification" || mode === "all") &&
        element.name === ENUM_NOTIFICATION_NAME.TRANSACTION_FUEL_TYPE
      ) {
        const newArray: string[] = [];
        fuelTypeNotificationOptions.forEach((value: boolean, key: string) => {
          if (value) {
            newArray.push(key);
          }
        });
        newObject.optionValues = newArray;
        newNotificationsObject.optionValues = newArray;
      }
      if (
        (mode === "transactionApproved" || mode === "all") &&
        element.name === ENUM_NOTIFICATION_NAME.TRANSACTION_APPROVED
      ) {
        newObject.optionValues = selectedTransactionApprovedCategory;
        newNotificationsObject.optionValues =
          selectedTransactionApprovedCategory;
      }
      if (
        (mode === "balance" || mode === "all") &&
        element.name === ENUM_NOTIFICATION_NAME.LOW_BALANCE
      ) {
        newObject.variables = balanceValue;
      }
      if (
        (mode === "aboveAmount" || mode === "all") &&
        element.name === ENUM_NOTIFICATION_NAME.TRANSACTION_ABOVE_AMOUNT
      ) {
        newObject.variables = transactionAboveAmount;
      }
      dataNew.push(newObject);
      dataNewNotifications.push(newNotificationsObject);
    });

    updateUserNotifications({
      variables: {
        data: {
          notifications: dataNew,
        },
      },
    })
      .then(({ data }) => {
        if (data?.updateUserNotifications?.code === "200") {
          Toast({
            type: "success",
            message: data?.updateUserNotifications?.message,
          });
        }
        setUpdating(false);
      })
      .catch((err) => {
        Toast({ type: "error", message: err.message });
      });
    setNotifications(dataNewNotifications);
  };

  const handleChangeDeclineOptions = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newDeclineOptions = new Map(declineOptions);
    const res = labels.filter(
      ({ stringValue }) => stringValue === e.target.name,
    );
    newDeclineOptions.set(res[0].value, e.target.checked);
    setdeclineOptions(newDeclineOptions);
  };
  const handleChangeFlagTransactionOptions = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newTransactionFlagOptions = new Map(transactionFlagOptions);
    const res = flaggedTransactionLabels.filter(
      ({ stringValue }) => stringValue === e.target.name,
    );
    newTransactionFlagOptions.set(res[0].value, e.target.checked);
    setTransactionFlagOptions(newTransactionFlagOptions);
  };

  const handleChangeFuelTypeNotification = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newFuelTypeNotificationOptions = new Map(fuelTypeNotificationOptions);
    const res = fuelTypesNotificationLabels.filter(
      ({ stringValue }) => stringValue === e.target.name,
    );
    newFuelTypeNotificationOptions.set(res[0].value, e.target.checked);
    setFuelTypeNotificationOptions(newFuelTypeNotificationOptions);
  };

  const handleChangeTransactionApproved = (e: MultiSelectChangeParams) => {
    setSelectedTransactionApprovedCategory(e.target.value);
  };

  const handleBalanceAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBalanceValue(e.target.value);
  };

  const handleTransactionAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransactionAboveAmount(e.target.value);
  };

  return (
    <div className="text-sm md:text-base">
      {access.BillingNotificationAccess && (
        <div className="py-8 mt-8 bg-white rounded-md shadow md:text-base">
          <div className="flex flex-col gap-8 overflow-hidden">
            <div className="px-4 font-medium">
              <div className="font-semibold text-brown-500 md:text-lg">
                Billing and Payment Notifications
              </div>
            </div>
            <div className="h-0.5 border-0 bg-brown-500"></div>
            <div className="items-center w-full px-4">
              {BillingAndPayment.map((entry) => (
                <div key={entry.name} className="w-full">
                  <div className="flex flex-row items-center justify-between w-full mb-8">
                    <div className="text-left">{entry.description}</div>
                    <div className="flex items-center ml-4 text-left">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              entry?.isEmailDisabled ? "text-gray-300" : "",
                              "mr-2",
                            )}
                          >
                            Email
                          </span>
                          {!entry.isEmailDisabled && (
                            <InputSwitch
                              name={`${entry.name} Email`}
                              checked={entry?.email}
                              onChange={handleChange}
                              className="border-0 input-switch"
                              disabled={entry?.isEmailDisabled || updating}
                            />
                          )}
                          {entry.isEmailDisabled && (
                            <div className="ml-2 mr-3">
                              <MinusCircleIcon
                                className="text-gray-300 w-7 h-7"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              entry?.isSmsDisabled ? "text-gray-300" : "",
                              "mx-3",
                            )}
                          >
                            SMS
                          </span>
                          {!entry.isSmsDisabled && (
                            <InputSwitch
                              name={`${entry.name} Sms`}
                              checked={entry?.sms}
                              onChange={handleChange}
                              className="border-0 input-switch"
                              disabled={entry?.isSmsDisabled || updating}
                            />
                          )}
                          {entry.isSmsDisabled && (
                            <div className="ml-2 mr-3">
                              <MinusCircleIcon
                                className="text-gray-300 w-7 h-7"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {access.TransactionNotificationAccess && (
        <div className="py-8 mt-8 bg-white rounded-md shadow md:text-base">
          <div className="flex flex-col gap-8 overflow-hidden">
            <div className="px-4 font-medium">
              <div className="font-semibold text-brown-500 md:text-lg">
                Transaction Notifications
              </div>
            </div>
            <div className="h-0.5 border-0 bg-brown-500"></div>
            <div className="items-center w-full px-4 my-4">
              {Transaction.map((entry) => (
                <div key={entry.name} className="w-full mb-8">
                  <div className="flex flex-row items-center justify-between w-full">
                    <span className="">
                      <span className="text-left">{entry.description}</span>
                      {entry?.name ===
                        ENUM_NOTIFICATION_NAME.TRANSACTION_APPROVED && (
                        <span className="font-normal text-yellow-600">
                          {" "}
                          (Enterprise)
                        </span>
                      )}
                    </span>

                    <div className="flex items-center ml-4 text-left">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              entry?.isEmailDisabled ? "text-gray-300" : "",
                              "mr-2",
                            )}
                          >
                            Email
                          </span>
                          {!entry.isEmailDisabled &&
                            (entry?.name ===
                            ENUM_NOTIFICATION_NAME.TRANSACTION_APPROVED ? (
                              <FuelCardComponentPermissionGuard
                                type="inputswitch"
                                featurePermission={
                                  access.TransactionApprovedAccess
                                }
                                messageType="enterprise"
                              >
                                <InputSwitch
                                  name={`${entry.name} Email`}
                                  checked={entry?.email}
                                  onChange={handleChange}
                                  className="border-0 input-switch"
                                  disabled={entry?.isEmailDisabled || updating}
                                />
                              </FuelCardComponentPermissionGuard>
                            ) : (
                              <InputSwitch
                                name={`${entry.name} Email`}
                                checked={entry?.email}
                                onChange={handleChange}
                                className="border-0 input-switch"
                                disabled={entry?.isEmailDisabled || updating}
                              />
                            ))}
                          {entry.isEmailDisabled && (
                            <div className="ml-2 mr-3">
                              <MinusCircleIcon
                                className="text-gray-300 w-7 h-7"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              entry?.isSmsDisabled ? "text-gray-300" : "",
                              "mx-3",
                            )}
                          >
                            SMS
                          </span>
                          {!entry.isSmsDisabled && (
                            <InputSwitch
                              name={`${entry.name} Sms`}
                              checked={entry?.sms}
                              onChange={handleChange}
                              className="border-0 input-switch"
                              disabled={entry?.isSmsDisabled || updating}
                            />
                          )}
                          {entry.isSmsDisabled && (
                            <div className="ml-2 mr-3">
                              <MinusCircleIcon
                                className="text-gray-300 w-7 h-7"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {entry?.name ===
                    ENUM_NOTIFICATION_NAME.TRANSACTION_DECLINED && (
                    <div>
                      <div className="relative col-span-12 my-4 sm:col-span-12">
                        <div className="mb-2 text-xs whitespace-nowrap lg:text-sm">
                          Decline Notification Types (Select the decline
                          notifications you want to receive)
                        </div>
                        {labels.map((options) => (
                          <div
                            className="relative flex items-start col-span-12 sm:col-span-12"
                            key={options.label}
                          >
                            <div className="flex items-center h-5 col-span-12 sm:col-span-12">
                              <CheckboxInput
                                label={humanize(options.label)}
                                labelClass="text-sm"
                                name={options.value}
                                checked={
                                  declineOptions.get(options.value) || false
                                }
                                onChange={handleChangeDeclineOptions}
                                disabled={
                                  entry.communicationMode ===
                                  ENUM_NOTIFICATION_CHANNEL.NONE
                                }
                              ></CheckboxInput>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {entry?.name ===
                    ENUM_NOTIFICATION_NAME.TRANSACTION_ABOVE_AMOUNT && (
                    <div>
                      <div className="relative col-span-12 my-4 sm:col-span-12">
                        <div className="mb-2 text-xs whitespace-nowrap lg:text-sm">
                          Select the amount that will trigger the notification
                        </div>
                        {transactionAboveLabels.map((options) => (
                          <div
                            className="relative flex items-start col-span-12 sm:col-span-12"
                            key={`transactionAboveGroup${options.label}`}
                          >
                            <div className="flex items-center h-5 col-span-12 sm:col-span-12">
                              <RadioInput
                                label={humanize(options.label)}
                                labelClass="text-sm"
                                name={"transactionAboveGroup"}
                                checked={
                                  transactionAboveAmount === options.value
                                }
                                className="!rounded"
                                onChange={handleTransactionAmount}
                                disabled={
                                  entry.communicationMode ===
                                  ENUM_NOTIFICATION_CHANNEL.NONE
                                }
                                value={options.value}
                                id={`transactionAboveLabel${options.label}`}
                              ></RadioInput>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {entry?.name ===
                    ENUM_NOTIFICATION_NAME.TRANSACTION_FLAGGED && (
                    <div>
                      <div className="relative col-span-12 my-4 sm:col-span-12">
                        <div className="mb-2 text-xs whitespace-nowrap lg:text-sm">
                          Flagged Notification Types (Select the flagged
                          notifications you want to receive)
                        </div>
                        {flaggedTransactionLabels.map((options) => (
                          <div
                            className="relative flex items-start col-span-12 sm:col-span-12"
                            key={options.label}
                          >
                            <div className="flex items-center h-5 col-span-12 sm:col-span-12">
                              <CheckboxInput
                                label={humanize(options.label)}
                                id={options.label}
                                type="checkbox"
                                name={options.value}
                                checked={
                                  transactionFlagOptions.get(options.value) ||
                                  false
                                }
                                onChange={handleChangeFlagTransactionOptions}
                                disabled={
                                  entry.communicationMode ===
                                  ENUM_NOTIFICATION_CHANNEL.NONE
                                }
                              ></CheckboxInput>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {entry?.name ===
                    ENUM_NOTIFICATION_NAME.TRANSACTION_FUEL_TYPE && (
                    <div>
                      <div className="relative col-span-12 my-4 sm:col-span-12">
                        <div className="mb-2 text-xs whitespace-nowrap lg:text-sm">
                          Fuel Types
                        </div>
                        {fuelTypesNotificationLabels.map((options) => (
                          <div
                            className="relative flex items-start col-span-12 sm:col-span-12"
                            key={options.label}
                          >
                            <div className="flex items-center h-5 col-span-12 sm:col-span-12">
                              <CheckboxInput
                                label={humanize(options.label)}
                                labelClass="text-sm"
                                id={options.label}
                                type="checkbox"
                                name={options.value}
                                checked={
                                  fuelTypeNotificationOptions.get(
                                    options.value,
                                  ) || false
                                }
                                onChange={handleChangeFuelTypeNotification}
                                disabled={
                                  entry.communicationMode ===
                                  ENUM_NOTIFICATION_CHANNEL.NONE
                                }
                              ></CheckboxInput>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {entry?.name ===
                    ENUM_NOTIFICATION_NAME.TRANSACTION_APPROVED && (
                    <div>
                      <div className="relative col-span-12 my-4 sm:col-span-12">
                        <div className="mb-2 text-xs whitespace-nowrap lg:text-sm">
                          Select Merchant Category
                        </div>
                        <CustomMultiSelect
                          value={selectedTransactionApprovedCategory}
                          options={[
                            {
                              label: "-- Select multiple options --",
                              value: "",
                              disabled: true,
                            },
                            {
                              label: "auto_service_shops",
                              value: "auto_service_shops",
                              disabled: false,
                            },
                          ].concat(
                            CATEGORIES_MASTER.split(",").map((categories) => {
                              return {
                                label: categories,
                                value: categories,
                                disabled: false,
                              };
                            }),
                          )}
                          onChange={(e) => {
                            handleChangeTransactionApproved(e);
                          }}
                          disabled={
                            entry.communicationMode ===
                            ENUM_NOTIFICATION_CHANNEL.NONE
                          }
                          placeholder="-- Select multiple options --"
                          className="max-w-[250px]"
                          optionLabel="label"
                          optionValue="value"
                          maxSelectedLabels={1}
                        ></CustomMultiSelect>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {access.AccountNotificationAccess && (
        <div className="py-8 mt-8 bg-white rounded-md shadow md:text-base">
          <div className="flex flex-col gap-8 overflow-hidden ">
            <div className="px-4 font-medium">
              <div className="font-semibold text-brown-500 md:text-lg">
                Account Notifications
              </div>
            </div>
            <div className="h-0.5 border-0 bg-brown-500"></div>
            <div className="items-center w-full px-4">
              {Account.map((entry) => (
                <div className="w-full" key={entry.name}>
                  <div className="flex flex-row items-center justify-between w-full mb-8">
                    <div className="text-left">{entry.description}</div>
                    <div className="flex items-center ml-4 text-left">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              entry?.isEmailDisabled ? "text-gray-300" : "",
                              "mr-2",
                            )}
                          >
                            Email
                          </span>
                          {!entry.isEmailDisabled && (
                            <InputSwitch
                              name={`${entry.name} Email`}
                              checked={entry?.email}
                              onChange={handleChange}
                              className="border-0 input-switch"
                              disabled={entry?.isEmailDisabled || updating}
                            />
                          )}
                          {entry.isEmailDisabled && (
                            <div className="ml-2 mr-3">
                              <MinusCircleIcon
                                className="text-gray-300 w-7 h-7"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              entry?.isSmsDisabled ? "text-gray-300" : "",
                              "mx-3",
                            )}
                          >
                            SMS
                          </span>
                          {!entry.isSmsDisabled && (
                            <InputSwitch
                              name={`${entry.name} Sms`}
                              checked={entry?.sms}
                              onChange={handleChange}
                              className="border-0 input-switch"
                              disabled={entry?.isSmsDisabled || updating}
                            />
                          )}
                          {entry.isSmsDisabled && (
                            <div className="ml-2 mr-3">
                              <MinusCircleIcon
                                className="text-gray-300 w-7 h-7"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {entry?.name === ENUM_NOTIFICATION_NAME.LOW_BALANCE && (
                    <div>
                      <div className="relative col-span-12 my-4 sm:col-span-12">
                        <div className="mb-2 text-xs whitespace-nowrap lg:text-sm">
                          Select the amount that will trigger the notification
                        </div>
                        {balanceLabels.map((options) => (
                          <div
                            className="relative flex items-start col-span-12 sm:col-span-12"
                            key={`balanceValueGroup${options.label}`}
                          >
                            <div className="flex items-center h-5 col-span-12 sm:col-span-12">
                              <RadioInput
                                label={humanize(options.label)}
                                labelClass="text-sm"
                                id={`balanceLabel${options.label}`}
                                type="radio"
                                className="!rounded"
                                value={options.value}
                                name="balanceValueGroup"
                                checked={balanceValue === options.value}
                                onChange={handleBalanceAmount}
                                disabled={
                                  entry.communicationMode ===
                                  ENUM_NOTIFICATION_CHANNEL.NONE
                                }
                              ></RadioInput>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {access.AnalysisNotificationAccess && (
        <div className="py-8 mt-8 bg-white rounded-md shadow md:text-base">
          <div className="flex flex-col gap-8 overflow-hidden ">
            <div className="px-4 font-medium">
              <div className="font-semibold text-brown-500 md:text-lg">
                Analysis{" "}
                <span className="font-normal text-yellow-600">
                  (Enterprise)
                </span>
              </div>
            </div>
            <div className="h-0.5 border-0 bg-brown-500"></div>
            <div className="items-center w-full px-4">
              {Analysis.map((entry) => (
                <div className="w-full" key={entry.name}>
                  <div className="flex flex-row items-center justify-between w-full mb-8">
                    <div className="text-left">
                      {entry.description}{" "}
                      <span className="font-normal text-yellow-600">
                        (Enterprise)
                      </span>
                    </div>
                    <div className="flex items-center ml-4 text-left">
                      <div className="flex items-center ml-4 text-left">
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                entry?.isEmailDisabled ? "text-gray-300" : "",
                                "mr-2",
                              )}
                            >
                              Email
                            </span>
                            {!entry.isEmailDisabled && (
                              <FuelCardComponentPermissionGuard
                                type="inputswitch"
                                featurePermission={
                                  access.AnalysisNotificationAccess
                                }
                                messageType="enterprise"
                              >
                                <InputSwitch
                                  name={`${entry.name} Email`}
                                  checked={entry?.email}
                                  onChange={handleChange}
                                  className="border-0 input-switch"
                                  disabled={entry?.isEmailDisabled || updating}
                                />
                              </FuelCardComponentPermissionGuard>
                            )}
                          </div>
                          {entry.isEmailDisabled && (
                            <div className="ml-2 mr-3">
                              <MinusCircleIcon
                                className="text-gray-300 w-7 h-7"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex items-center text-left">
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                entry?.isSmsDisabled ? "text-gray-300" : "",
                                "mx-3",
                              )}
                            >
                              SMS
                            </span>
                            {!entry.isSmsDisabled && (
                              <FuelCardComponentPermissionGuard
                                type="inputswitch"
                                featurePermission={
                                  access.AnalysisNotificationAccess
                                }
                                messageType="enterprise"
                              >
                                <InputSwitch
                                  name={`${entry.name} Sms`}
                                  checked={entry?.sms}
                                  onChange={handleChange}
                                  className="border-0 input-switch"
                                  disabled={entry?.isSmsDisabled || updating}
                                />
                              </FuelCardComponentPermissionGuard>
                            )}
                            {entry.isSmsDisabled && (
                              <div className="ml-2 mr-3">
                                <MinusCircleIcon
                                  className="text-gray-300 w-7 h-7"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-5">
        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.AppSize}
          loading={updating}
          disabled={updating}
          onClick={() => saveVariable("all")}
        >
          <span className="text-base">Save</span>
        </Button>
      </div>
    </div>
  );
};

export default PersonalNotificationsUI;
