/** @format */

import { useMutation } from "@apollo/client";
import {
  ArrowPathIcon,
  ChevronRightIcon,
  FolderOpenIcon,
} from "@heroicons/react/24/outline";
import { ENUM_ELD_ORG_NAME } from "@prisma/client";
import { GEOTAB_GET_DEVICES, SYNC_ELD_DEVICE } from "@roadflex/graphql";
import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  EldDevices,
  FleetExtended,
  GeoTabGetDevicesValues,
  MapType,
  TripDetails,
} from "@roadflex/types";
import { FormikProps } from "formik";
import { useRouter } from "next/router";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useState } from "react";
import { JsonDataViewerModal } from "../../admin-modals";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { Toast } from "../../toast-message/toast";
import EditGeoTabDeviceModal from "./edit-geotab-device.modal.ui";
import GMapsModal from "./g-maps.modal.ui";
import GeoTabDeviceTripsModal from "./geotab-device-trip-list.modal.ui";

interface EldDetailsPageUIProps {
  geoTabDevices: EldDevices[];
  samsaraDevices: EldDevices[];
  azugaDevices: EldDevices[];
  motiveDevices: EldDevices[];
  uthDevices: EldDevices[];
  fleetUpDevices: EldDevices[];
  verizonDevices: EldDevices[];
  openGeoTabDeviceTripsModal: boolean;
  setOpenGeoTabDeviceTripsModal: (value: boolean) => void;
  openGeoTabTripDetailsModal: (rowData: EldDevices) => void;
  openGMapsModal: boolean;
  setOpenGMapsModal: (value: boolean) => void;
  setMapData: (rowData: EldDevices) => void;
  mapValues: MapType;
  tripDetailValues: TripDetails;
  orgName: string;
  setOrgName: (value: string) => void;
  database: string;
  openEditGeoTabDeviceModal: boolean;
  setOpenEditGeoTabDeviceModal: (value: boolean) => void;
  openEditGeoTabDetailsModal: (rowData: EldDevices) => void;
  editGeoTabDeviceValues: {
    geoTabDeviceId: string;
    geoTabDeviceName: string;
    vehicleId: string;
  };
  setEditGeoTabDeviceValues: (value: {
    geoTabDeviceId: string;
    geoTabDeviceName: string;
    vehicleId: string;
  }) => void;
  vehiclesList: FleetExtended[];
  getVehiclesLoading: boolean;
  setMapValues: (value: MapType) => void;
  geoTabGetDevicesForm: FormikProps<GeoTabGetDevicesValues>;
}

export const EldDetailsPageUI = ({
  geoTabDevices,
  samsaraDevices,
  azugaDevices,
  motiveDevices,
  uthDevices,
  fleetUpDevices,
  verizonDevices,
  openGeoTabDeviceTripsModal,
  setOpenGeoTabDeviceTripsModal,
  openGeoTabTripDetailsModal,
  openGMapsModal,
  setOpenGMapsModal,
  setMapData,
  mapValues,
  tripDetailValues,
  orgName,
  setOrgName,
  database,
  openEditGeoTabDeviceModal,
  setOpenEditGeoTabDeviceModal,
  openEditGeoTabDetailsModal,
  editGeoTabDeviceValues,
  setEditGeoTabDeviceValues,
  vehiclesList,
  getVehiclesLoading,
  setMapValues,
  geoTabGetDevicesForm,
}: EldDetailsPageUIProps) => {
  const { isMobile } = usePlatformDetect();
  const router = useRouter();

  const backToEldPanel = () => {
    router.push("/eld-systems");
  };

  const { isSubmitting: geoTabGetDevicesIsSubmitting } = geoTabGetDevicesForm;
  const [openFuelAddIn, setOpenFuelAddIn] = useState(false);

  const selectedData = `{
	"name": "RoadFlex",
	"supportEmail": "support@roadflex.com",
	"version": "1.0.1",
	"items": [
		{
			"path": "/",
			"menuName": {
				"en": "Roadflex"
			},
			"icon": "https://storage.googleapis.com/public-external-roadflex-com/fuel_transaction_data/dist/images/icon.svg",
			"menuId": "sdkAddinsLink"
		},
		{
			"url": "https://storage.googleapis.com/public-external-roadflex-com/fuel_transaction_data/app/fuelTransactionData_4.html",
			"path": "sdkAddinsLink/",
			"menuName": {
				"en": "Fuel Transaction Data"
			},
			"icon": "https://storage.googleapis.com/public-external-roadflex-com/fuel_transaction_data/dist/images/stop-start-icon.svg"
		}
	],
	"isSigned": false
}`;

  const [syncEldDeviceFn] = useMutation(SYNC_ELD_DEVICE, {
    refetchQueries: [GEOTAB_GET_DEVICES],
  });

  const syncEldDevice = async (orgNames: string) => {
    try {
      await syncEldDeviceFn({
        variables: {
          data: {
            orgName: orgNames,
          },
        },
      });
      Toast({ type: "success", message: "Device synced successfully" });
      geoTabGetDevicesForm.handleSubmit();
    } catch (err) {
      if (err instanceof Error) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
    }
  };

  const fleetNameTemplate = (rowData: EldDevices) => {
    return (
      <div className="flex items-center justify-between gap-2 text-xs text-gray-500 whitespace-nowrap">
        <div>{rowData?.fleetName || "-"}</div>
        <Button
          variant={ButtonVariant.AppOrange}
          size={ButtonSize.Small}
          className="text-xs"
          onClick={(e) => {
            openEditGeoTabDetailsModal(rowData);
          }}
        >
          Edit
        </Button>
      </div>
    );
  };

  const showDeviceOnMapTemplate = (rowData: EldDevices) => {
    return (
      <div className="flex items-center gap-4">
        <Button
          variant={ButtonVariant.AppOrange}
          size={ButtonSize.Small}
          className="text-xs"
          onClick={(e) => {
            setMapData(rowData);
          }}
        >
          Live Location
        </Button>
        {!(
          [
            ENUM_ELD_ORG_NAME.UTH,
            ENUM_ELD_ORG_NAME.MOTIVE,
            ENUM_ELD_ORG_NAME.FLEETUP,
          ] as string[]
        ).includes(orgName) && (
          <Button
            variant={ButtonVariant.AppOrange}
            size={ButtonSize.Small}
            className="text-xs"
            onClick={(e) => {
              openGeoTabTripDetailsModal(rowData);
            }}
          >
            Trips
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className="flex-col flex flex-1 md:px-4 md:pb-4 mt-0 m-4 h-[calc(100vh-96px)]">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-row items-center">
              <div className="">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Transparent}
                  onClick={backToEldPanel}
                  className="pl-0 ml-[-6px] border-0"
                >
                  <span className="flex items-center h-5">
                    <ChevronRightIcon
                      className={
                        "-rotate-180 h-5 w-5 transform ease-in duration-300"
                      }
                      aria-hidden="true"
                    />
                  </span>
                  Back
                </Button>
              </div>
              <div className="ml-4 text-sm font-medium md:text-lg ">
                {orgName}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 overflow-hidden rounded-md md:mt-0">
          <div className="font-medium ">
            <div className="bg-transparent ">
              <div className="flex flex-row justify-end w-full">
                {orgName === ENUM_ELD_ORG_NAME.GEOTAB && (
                  <div className="flex items-center mr-4">
                    <Button
                      variant={ButtonVariant.PrimaryOutline}
                      size={ButtonSize.AppSize}
                      className=""
                      onClick={(e) => {
                        setOpenFuelAddIn(true);
                      }}
                    >
                      Fuel Add-in Code
                      <FolderOpenIcon
                        className="w-6 h-6 ml-1 text-black"
                        aria-hidden="true"
                      />
                    </Button>
                  </div>
                )}
                <div className="flex items-center gap-4 my-4 bg-white rounded-md shadow">
                  <Button
                    variant={ButtonVariant.PrimaryOutline}
                    size={ButtonSize.AppSize}
                    className=""
                    onClick={(e) => {
                      syncEldDevice(orgName);
                    }}
                  >
                    <ArrowPathIcon
                      className="w-6 h-6 text-black"
                      aria-hidden="true"
                    />
                  </Button>
                </div>
              </div>
              {orgName === ENUM_ELD_ORG_NAME.GEOTAB && (
                <div className="p-4 overflow-hidden bg-white rounded-md shadow md:m-0">
                  <div className="font-medium">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
                      <DataTable
                        size="small"
                        stripedRows
                        loading={
                          (getVehiclesLoading ||
                            geoTabGetDevicesIsSubmitting) &&
                          orgName === ENUM_ELD_ORG_NAME.GEOTAB
                        }
                        value={geoTabDevices}
                        paginator
                        rows={10}
                        dataKey="id"
                        sortField="deviceName"
                        sortOrder={1}
                        paginatorTemplate={newPaginationTemplate}
                        pageLinkSize={isMobile() ? 1 : 5}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 50]}
                        responsiveLayout="stack"
                        className="custom-table"
                        emptyMessage="No available records"
                        style={{ fontFamily: "Inter" }}
                      >
                        <Column
                          sortable
                          filterPlaceholder="Search by ID"
                          field="id"
                          header="ID"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by device name"
                          field="deviceName"
                          header="Name"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by vehicle number"
                          header="Map to Vehicle"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          body={fleetNameTemplate}
                        />
                        <Column
                          sortable
                          field="serialNumber"
                          header="Serial Number"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          field="deviceType"
                          header="Type"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          header="Details"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          body={showDeviceOnMapTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              )}
              {orgName === ENUM_ELD_ORG_NAME.SAMSARA && (
                <div className="p-4 overflow-hidden bg-white rounded-md shadow md:m-0">
                  <div className="font-medium">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
                      <DataTable
                        size="small"
                        stripedRows
                        loading={
                          getVehiclesLoading &&
                          orgName === ENUM_ELD_ORG_NAME.SAMSARA
                        }
                        value={samsaraDevices}
                        paginator
                        rows={10}
                        dataKey="id"
                        sortField="deviceName"
                        sortOrder={1}
                        paginatorTemplate={newPaginationTemplate}
                        pageLinkSize={isMobile() ? 1 : 5}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 50]}
                        responsiveLayout="stack"
                        className="custom-table"
                        emptyMessage="No available records"
                        style={{ fontFamily: "Inter" }}
                      >
                        <Column
                          sortable
                          filterPlaceholder="Search by ID"
                          field="id"
                          header="ID"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by device name"
                          field="deviceName"
                          header="Name"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by vehicle number"
                          header="Map to Vehicle"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          body={fleetNameTemplate}
                        />
                        <Column
                          sortable
                          field="serialNumber"
                          header="Serial Number"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          field="deviceType"
                          header="Type"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          header="Details"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          body={showDeviceOnMapTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              )}
              {orgName === ENUM_ELD_ORG_NAME.AZUGA && (
                <div className="p-4 overflow-hidden bg-white rounded-md md:m-0">
                  <div className="font-medium">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
                      <DataTable
                        size="small"
                        stripedRows
                        loading={
                          getVehiclesLoading &&
                          orgName === ENUM_ELD_ORG_NAME.AZUGA
                        }
                        value={azugaDevices}
                        paginator
                        rows={10}
                        dataKey="id"
                        sortField="deviceName"
                        sortOrder={1}
                        paginatorTemplate={newPaginationTemplate}
                        pageLinkSize={isMobile() ? 1 : 5}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 50]}
                        responsiveLayout="stack"
                        className="custom-table"
                        emptyMessage="No available records"
                        style={{ fontFamily: "Inter" }}
                      >
                        <Column
                          sortable
                          filterPlaceholder="Search by ID"
                          field="id"
                          header="ID"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by device name"
                          field="deviceName"
                          header="Name"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by vehicle number"
                          header="Map to Vehicle"
                          className="text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          body={fleetNameTemplate}
                        />
                        <Column
                          sortable
                          field="serialNumber"
                          header="Serial Number"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          field="deviceType"
                          header="Type"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          header="Details"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          body={showDeviceOnMapTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              )}
              {orgName === ENUM_ELD_ORG_NAME.MOTIVE && (
                <div className="p-4 overflow-hidden bg-white rounded-md md:m-0">
                  <div className="font-medium">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
                      <DataTable
                        size="small"
                        stripedRows
                        loading={
                          getVehiclesLoading &&
                          orgName === ENUM_ELD_ORG_NAME.MOTIVE
                        }
                        value={motiveDevices}
                        paginator
                        rows={10}
                        dataKey="id"
                        sortField="deviceName"
                        sortOrder={1}
                        paginatorTemplate={newPaginationTemplate}
                        pageLinkSize={isMobile() ? 1 : 5}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 50]}
                        responsiveLayout="stack"
                        className="custom-table"
                        emptyMessage="No available records"
                        style={{ fontFamily: "Inter" }}
                      >
                        <Column
                          sortable
                          filterPlaceholder="Search by ID"
                          field="id"
                          header="ID"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by device name"
                          field="deviceName"
                          header="Name"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by vehicle number"
                          header="Map to Vehicle"
                          className="text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          body={fleetNameTemplate}
                        />
                        <Column
                          sortable
                          field="serialNumber"
                          header="Serial Number"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          field="deviceType"
                          header="Type"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          header="Details"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          body={showDeviceOnMapTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              )}
              {orgName === ENUM_ELD_ORG_NAME.UTH && (
                <div className="p-4 overflow-hidden bg-white rounded-md md:m-0">
                  <div className="font-medium">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
                      <DataTable
                        size="small"
                        stripedRows
                        loading={
                          getVehiclesLoading &&
                          orgName === ENUM_ELD_ORG_NAME.UTH
                        }
                        value={uthDevices}
                        paginator
                        rows={10}
                        dataKey="id"
                        sortField="deviceName"
                        sortOrder={1}
                        paginatorTemplate={newPaginationTemplate}
                        pageLinkSize={isMobile() ? 1 : 5}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 50]}
                        responsiveLayout="stack"
                        className="custom-table"
                        emptyMessage="No available records"
                        style={{ fontFamily: "Inter" }}
                      >
                        <Column
                          sortable
                          filterPlaceholder="Search by ID"
                          field="id"
                          header="ID"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by device name"
                          field="deviceName"
                          header="Name"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by vehicle number"
                          header="Map to Vehicle"
                          className="text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          body={fleetNameTemplate}
                        />
                        <Column
                          sortable
                          field="serialNumber"
                          header="Serial Number"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          field="deviceType"
                          header="Type"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          header="Details"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          body={showDeviceOnMapTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              )}
              {orgName === ENUM_ELD_ORG_NAME.FLEETUP && (
                <div className="p-4 overflow-hidden bg-white rounded-md md:m-0">
                  <div className="font-medium">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
                      <DataTable
                        size="small"
                        stripedRows
                        loading={
                          getVehiclesLoading &&
                          orgName === ENUM_ELD_ORG_NAME.FLEETUP
                        }
                        value={fleetUpDevices}
                        paginator
                        rows={10}
                        dataKey="id"
                        sortField="deviceName"
                        sortOrder={1}
                        paginatorTemplate={newPaginationTemplate}
                        pageLinkSize={isMobile() ? 1 : 5}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 50]}
                        responsiveLayout="stack"
                        className="custom-table"
                        emptyMessage="No available records"
                        style={{ fontFamily: "Inter" }}
                      >
                        <Column
                          sortable
                          filterPlaceholder="Search by ID"
                          field="id"
                          header="ID"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by device name"
                          field="deviceName"
                          header="Name"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by vehicle number"
                          header="Map to Vehicle"
                          className="text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          body={fleetNameTemplate}
                        />
                        <Column
                          sortable
                          field="serialNumber"
                          header="Serial Number"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          field="deviceType"
                          header="Type"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          header="Details"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          body={showDeviceOnMapTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              )}
              {orgName === ENUM_ELD_ORG_NAME.VERIZON && (
                <div className="p-4 overflow-hidden bg-white rounded-md md:m-0">
                  <div className="font-medium">
                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
                      <DataTable
                        size="small"
                        stripedRows
                        loading={
                          getVehiclesLoading &&
                          orgName === ENUM_ELD_ORG_NAME.VERIZON
                        }
                        value={verizonDevices}
                        paginator
                        rows={10}
                        dataKey="id"
                        sortField="deviceName"
                        sortOrder={1}
                        paginatorTemplate={newPaginationTemplate}
                        pageLinkSize={isMobile() ? 1 : 5}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rowsPerPageOptions={[10, 20, 50]}
                        responsiveLayout="stack"
                        className="custom-table"
                        emptyMessage="No available records"
                        style={{ fontFamily: "Inter" }}
                      >
                        <Column
                          sortable
                          filterPlaceholder="Search by ID"
                          field="id"
                          header="ID"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by device name"
                          field="deviceName"
                          header="Name"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          filterPlaceholder="Search by vehicle number"
                          header="Map to Vehicle"
                          className="text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          body={fleetNameTemplate}
                        />
                        <Column
                          sortable
                          field="serialNumber"
                          header="Serial Number"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          sortable
                          field="deviceType"
                          header="Type"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                        />
                        <Column
                          header="Details"
                          className="text-sm 5xl:text-base"
                          bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                          headerClassName="!bg-white !text-brown-500 !border-brown-500"
                          body={showDeviceOnMapTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {openEditGeoTabDeviceModal && (
            <EditGeoTabDeviceModal
              {...{
                openEditGeoTabDeviceModal,
                setOpenEditGeoTabDeviceModal,
                editGeoTabDeviceValues,
                vehiclesList,
                ...geoTabGetDevicesForm,
              }}
            />
          )}
          {openGeoTabDeviceTripsModal && (
            <GeoTabDeviceTripsModal
              {...{
                openGeoTabDeviceTripsModal,
                setOpenGeoTabDeviceTripsModal,
                database,
                orgName,
                tripDetailValues,
              }}
            />
          )}
          {openGMapsModal && (
            <GMapsModal
              {...{
                openGMapsModal,
                setOpenGMapsModal,
                database,
                orgName,
                mapValues,
                setMapValues,
              }}
            />
          )}
          {openFuelAddIn && (
            <JsonDataViewerModal
              open={openFuelAddIn}
              setOpen={setOpenFuelAddIn}
              data={[JSON.parse(selectedData)]}
              header="Fuel Add-in"
              description="Sign in to your GeoTab account, under system settings, select Add-Ins. Copy this block of code and click save."
              mode="geotab"
            ></JsonDataViewerModal>
          )}
        </div>
      </div>
    </div>
  );
};
