import { useUpdateAccountDisplaySetting } from "@roadflex/react-hooks";
import { AccountSettingDisplayType } from "@roadflex/types";
import Toast from "../../../../../../libs/react-components/src/toast-message/toast";
import DisplaySettingsUI from "./display-settings.ui";

type DisplaySettingsProps = {
  readAccountDisplaySetting: AccountSettingDisplayType;
};

const DisplaySettings = ({
  readAccountDisplaySetting,
}: DisplaySettingsProps) => {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    handleReset,
    setFieldValue,
    ...rest
  } = useUpdateAccountDisplaySetting({
    initialValues: {
      colorScheme: readAccountDisplaySetting?.colorScheme,
      timeZone: readAccountDisplaySetting?.timeZone,
      showCompanyName: readAccountDisplaySetting?.showCompanyName,
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err?.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res?.message });
      }
    },
  });
  return (
    <DisplaySettingsUI
      {...{
        values,
        readAccountDisplaySetting,
        handleChange,
        handleSubmit,
        handleReset,
        isSubmitting,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        ...rest,
      }}
    />
  );
};
export default DisplaySettings;
