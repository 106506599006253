/** @format */

import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { STATE_CODES } from "@roadflex/constants";
import { AddressValues } from "@roadflex/types";
import { FormikProps } from "formik";
import { forwardRef, LegacyRef, Ref } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { Select } from "../dropdowns";
import { TextInput } from "../inputs";

interface HomeAddressUIProps extends FormikProps<AddressValues> {
  isSubmittingOnboardStatus: boolean;
  onPreviousStep: () => void;
}

const HomeAddressUI = forwardRef(
  (
    {
      values,
      handleChange,
      handleSubmit,
      handleBlur,
      isSubmitting,
      errors,
      touched,
      isSubmittingOnboardStatus,
      setFieldValue,
      setFieldTouched,
      dirty,
      onPreviousStep,
      ...rest
    }: HomeAddressUIProps,
    autoCompleteRef: unknown,
  ) => {
    const { homeAddressAutoCompleteRef = null } = (
      autoCompleteRef as { current: unknown }
    )?.current as {
      homeAddressAutoCompleteRef: LegacyRef<HTMLInputElement>;
    };
    return (
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        <div className="mb-1 text-xl font-semibold text-center text-white md:mb-4 md:text-4xl">
          Tell us about yourself (2 of 2)
        </div>
        <div className="mb-4 text-xs text-center text-white md:mb-8 md:text-sm">
          This form must be filled out by someone with significant control and
          management of the business. If that's not you, make sure to ask the
          right person to continue.
        </div>
        <div className="relative w-full">
          <TextInput
            label="Home Address* (PO Box not allowed)"
            name="homeAddress.streetAddress"
            ref={homeAddressAutoCompleteRef as unknown as Ref<HTMLInputElement>}
            value={values?.homeAddress?.streetAddress}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={30}
            error={
              touched?.homeAddress?.streetAddress &&
              Boolean(errors?.homeAddress?.streetAddress)
            }
            errorMessage={errors?.homeAddress?.streetAddress}
            touched={touched?.homeAddress?.streetAddress}
            variant="responsive"
            informationToolTip="A valid business address is required. Any typos or wrong address can lead to lengthened review times."
            color="text-white"
          ></TextInput>
        </div>
        <div className="relative w-full mt-4">
          <TextInput
            label="Suite # (Optional)"
            name="homeAddress.streetDetails"
            value={values?.homeAddress?.streetDetails}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={200}
            error={
              touched?.homeAddress?.streetDetails &&
              Boolean(errors?.homeAddress?.streetDetails)
            }
            errorMessage={errors?.homeAddress?.streetDetails}
            touched={touched?.homeAddress?.streetDetails}
            variant="responsive"
            color="text-white"
          ></TextInput>
        </div>
        <div className="relative w-full mt-4">
          <TextInput
            label="City"
            name="homeAddress.city"
            value={values?.homeAddress?.city}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={15}
            error={
              touched?.homeAddress?.city && Boolean(errors?.homeAddress?.city)
            }
            errorMessage={errors?.homeAddress?.city}
            touched={touched?.homeAddress?.city}
            variant="responsive"
            color="text-white"
            required
          ></TextInput>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="relative w-full mt-4">
            <Select
              label="State"
              value={values?.homeAddress?.state}
              options={[
                {
                  label: "-- Select a State--",
                  value: "",
                  disabled: true,
                },
              ].concat(
                STATE_CODES.map((m) => {
                  return {
                    label: `${m.label} (${m.value})`,
                    value: m.value,
                    disabled: false,
                  };
                }),
              )}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => setFieldValue("homeAddress.state", e.value)}
              onBlur={() => setFieldTouched("homeAddress.state", true)}
              placeholder="Select"
              touched={touched?.homeAddress?.state}
              error={
                touched?.homeAddress?.state &&
                Boolean(errors?.homeAddress?.state)
              }
              errorMessage={errors?.homeAddress?.state}
              required
              variant="responsive"
              color="text-white"
            ></Select>
          </div>

          <div className="relative w-full mt-4 sm:ml-4">
            <TextInput
              label="Zip"
              name="homeAddress.zip"
              value={values?.homeAddress?.zip}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={5}
              error={
                touched?.homeAddress?.zip && Boolean(errors?.homeAddress?.zip)
              }
              errorMessage={errors?.homeAddress?.zip}
              touched={touched?.homeAddress?.zip}
              variant="responsive"
              color="text-white"
              required
            ></TextInput>
          </div>
        </div>
        <div className="mt-4 text-xs text-white md:text-sm">
          *Required Fields
        </div>

        <div className="mt-4 space-y-4" role="group">
          <div className="flex items-center">
            <input
              id="owner"
              name="owner"
              type="checkbox"
              checked={values.owner}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-4 h-4 text-orange-600 border-0 cursor-pointer focus:ring-offset-0 focus:ring-0 focus:outline-none focus:shadow-none"
            />
            <label htmlFor="owner" className="block ml-3 text-white">
              I own 25% or more of this business
            </label>
          </div>
        </div>

        <div className="mt-6 text-right">
          <Button
            type={ButtonType.Submit}
            variant={ButtonVariant.Orange}
            className="w-full"
            disabled={isSubmitting || isSubmittingOnboardStatus}
            loading={isSubmitting || isSubmittingOnboardStatus}
          >
            <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>

            {dirty ? `Save` : `Next`}
          </Button>
        </div>
        <div>
          <div className="flex items-center justify-center mt-1 text-sm text-center text-white">
            <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
            We securely store and encrypt your personal information
          </div>
          <Button
            type={ButtonType.Button}
            variant={ButtonVariant.Transparent}
            className="pl-0 text-white"
            size={ButtonSize.Small}
            onClick={onPreviousStep}
          >
            &#60;&#60; Go back
          </Button>
        </div>
      </form>
    );
  },
);

export default HomeAddressUI;
