/** @format */

import {
  useReadAdditionalBusinessInformation,
  useReadAddresses,
  useReadBusinessInformation,
} from "@roadflex/react-hooks";
import {
  AdditionalBusinessInfoType,
  AddressType,
  BusinessType,
  UserOnboardType,
} from "@roadflex/types";
import { useState } from "react";
import ReviewUI from "./review-stage.ui";

type Props = {
  business: BusinessType;
  addresses: AddressType[];
  onJumpToStep: (step: number) => void;
  additionalBusinessInfo: AdditionalBusinessInfoType;
  isSubmittingApplication: boolean;
  onSubmitApplication: () => void;
  readCurrentUserOnboard: UserOnboardType;
  nextStep: (jumpStepBy?: number) => void;
  isSubmittingOnboardStatus: boolean;
  isSameAddress: boolean;
  onPreviousStep: () => void;
};

const Review = ({
  business,
  addresses,
  additionalBusinessInfo,
  onJumpToStep,
  isSubmittingApplication,
  onSubmitApplication,
  readCurrentUserOnboard,
  nextStep,
  isSubmittingOnboardStatus,
  isSameAddress,
  onPreviousStep,
}: Props) => {
  const [
    showRegistrationConfirmationModal,
    setShowRegistrationConfirmationModal,
  ] = useState(false);
  return (
    <ReviewUI
      business={business}
      addresses={addresses}
      additionalBusinessInfo={additionalBusinessInfo}
      readCurrentUserOnboard={readCurrentUserOnboard}
      onJumpToStep={onJumpToStep}
      onConfirm={onSubmitApplication}
      confirming={isSubmittingApplication}
      showRegistrationConfirmationModal={showRegistrationConfirmationModal}
      setShowRegistrationConfirmationModal={
        setShowRegistrationConfirmationModal
      }
      nextStep={nextStep}
      isSubmittingOnboardStatus={isSubmittingOnboardStatus}
      isSameAddress={isSameAddress}
      onPreviousStep={onPreviousStep}
    />
  );
};

function AddressAndAdditionalBusinessData(props: {
  onJumpToStep: (step: number) => void;
  business: BusinessType;
  isSubmittingApplication: boolean;
  onSubmitApplication: () => void;
  readCurrentUserOnboard: UserOnboardType;
  nextStep: (jumpStepBy?: number) => void;
  isSubmittingOnboardStatus: boolean;
  onPreviousStep: () => void;
}) {
  const { data, loading } = useReadAddresses({ businessId: props.business.id });

  const {
    data: additionalBusinessInformationData,
    loading: additionaBusinessInfoLoading,
  } = useReadAdditionalBusinessInformation();

  if (loading || additionaBusinessInfoLoading) {
    return <div className="mt-6 text-center text-white">Loading...</div>;
  }

  const addresses = data?.addresses || [];
  const isSameAddress = data?.isSameAddress || false;
  return (
    <Review
      {...{
        ...props,
        addresses,
        isSameAddress,
        additionalBusinessInfo:
          additionalBusinessInformationData.readAdditionalBusinessInformation,
      }}
    />
  );
}

function Business(props: {
  onJumpToStep: (step: number) => void;
  isSubmittingApplication: boolean;
  onSubmitApplication: () => void;
  readCurrentUserOnboard: UserOnboardType;
  nextStep: (jumpStepBy?: number) => void;
  isSubmittingOnboardStatus: boolean;
  onPreviousStep: () => void;
}) {
  const { data, loading } = useReadBusinessInformation();

  if (loading) {
    return <div className="mt-6 text-center text-white">Loading...</div>;
  }

  const { readBusinessInformation: business } = data;
  return (
    <AddressAndAdditionalBusinessData
      {...{ ...props, business, addresses: [] }}
    />
  );
}

export default Business;
