import { useLazyQuery } from "@apollo/client";
import {
  EyeIcon,
  LifebuoyIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { ENUM_CARD_TYPE } from "@prisma/client";
import { STRIPE_GET_EPHEMERAL_KEY } from "@roadflex/graphql";
import { useUpdateCardPin } from "@roadflex/react-hooks";
import { Card } from "@roadflex/types";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  StripeIssuingCardCvcDisplayElement,
  StripeIssuingCardExpiryDisplayElement,
  StripeIssuingCardNumberDisplayElement,
} from "@stripe/stripe-js";
import classNames from "classnames";
import getConfig from "next/config";
import Image from "next/image";
import { useEffect, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { TextInput } from "../../inputs";
import { FuelCardComponentPermissionGuard } from "../../permission-guard";
import { Toast } from "../../toast-message/toast";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export default function CardInformationUI({
  cardDetailsObj,
  access,
}: {
  cardDetailsObj: Card;
  access: boolean;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [nonce, setNonce] = useState("");
  const [cardRevealing, setCardRevealing] = useState(false);
  const [number, setNumber] =
    useState<StripeIssuingCardNumberDisplayElement | null>(null);
  const [cvv, setCvv] = useState<StripeIssuingCardCvcDisplayElement | null>(
    null,
  );
  const [pin, setPin] = useState<StripeIssuingCardCvcDisplayElement | null>(
    null,
  );
  const [expiry, setExpiry] =
    useState<StripeIssuingCardExpiryDisplayElement | null>(null);
  const {
    handleSubmit: cardPinHandleSubmit,
    values: cardPinValues,
    setFieldValue: cardPinSetFieldValue,
    handleBlur: cardPinHandleBlur,
    resetForm: cardPinResetForm,
    handleChange: cardPinHandleChange,
    errors: cardPinErrors,
    touched: cardPinTouched,
    isSubmitting: cardPinIsSubmitting,
  } = useUpdateCardPin({
    initialValues: {
      cardId: cardDetailsObj.cardId || "",
      pin: "",
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res.message });
      }
      cardPinResetForm();
    },
  });

  const [getEphemeralSecretFn] = useLazyQuery<{
    stripeGetEphemeralKey: {
      code: string;
      message: string;
      ephemeralKeySecret: string;
    };
  }>(STRIPE_GET_EPHEMERAL_KEY, {
    onCompleted: (data) => {
      if (data.stripeGetEphemeralKey.code !== "200" || !elements) {
        setCardRevealing(false);
        Toast({ type: "error", message: data.stripeGetEphemeralKey.message });
        return;
      }
      if (elements) {
        let num, exp, threeDigitCode, pinElement;
        if (cardDetailsObj.cardType === ENUM_CARD_TYPE.VIRTUAL) {
          num = elements.create("issuingCardNumberDisplay", {
            issuingCard: cardDetailsObj?.cardId as string,
            nonce: nonce,
            ephemeralKeySecret: data.stripeGetEphemeralKey.ephemeralKeySecret,
            style: {
              base: {
                color: "#fff",
                fontSize: "24px",
                fontStyle: "Inter",
              },
            },
          });
          exp = elements.create("issuingCardExpiryDisplay", {
            issuingCard: cardDetailsObj?.cardId as string,
            nonce: nonce,
            ephemeralKeySecret: data.stripeGetEphemeralKey.ephemeralKeySecret,
            style: {
              base: {
                color: "#fff",
                fontSize: "24px",
                fontStyle: "Inter",
              },
            },
          });
          threeDigitCode = elements.create("issuingCardCvcDisplay", {
            issuingCard: cardDetailsObj?.cardId as string,
            nonce: nonce,
            ephemeralKeySecret: data.stripeGetEphemeralKey.ephemeralKeySecret,
            style: {
              base: {
                color: "#fff",
                fontSize: "24px",
                fontStyle: "Inter",
              },
            },
          });
        } else if (cardDetailsObj.cardType === ENUM_CARD_TYPE.PHYSICAL) {
          pinElement = elements.create("issuingCardPinDisplay", {
            issuingCard: cardDetailsObj?.cardId as string,
            nonce: nonce,
            ephemeralKeySecret: data.stripeGetEphemeralKey.ephemeralKeySecret,
            style: {
              base: {
                color: "#893F2B",
                fontSize: "20px",
                fontStyle: "Inter",
              },
            },
          });
        }

        if (num) {
          num.mount("#card-number");
          setNumber(num);
        }
        if (exp) {
          exp.mount("#expiry");
          setExpiry(exp);
        }
        if (threeDigitCode) {
          threeDigitCode.mount("#cvv");
          setCvv(threeDigitCode);
        }
        if (pinElement) {
          pinElement.mount("#card-pin");
          setPin(pinElement);
        }

        setTimeout(() => {
          setCardRevealing(false);
        }, 2000);
      }
    },
  });

  useEffect(() => {
    if (stripe) {
      if (!pin && cardDetailsObj.cardType === ENUM_CARD_TYPE.PHYSICAL) {
        stripe
          .createEphemeralKeyNonce({
            issuingCard: cardDetailsObj.cardId as string,
          })
          .then((value) => setNonce(value?.nonce || ""));
      }
      if (!number && cardDetailsObj.cardType === ENUM_CARD_TYPE.VIRTUAL) {
        stripe
          .createEphemeralKeyNonce({
            issuingCard: cardDetailsObj.cardId as string,
          })
          .then((value) => setNonce(value?.nonce || ""));
      }
    }
  }, [stripe, pin, number]);

  useEffect(() => {
    cardPinSetFieldValue("cardId", cardDetailsObj.cardId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDetailsObj.cardId]);

  const onReveal = () => {
    setCardRevealing(true);
    getEphemeralSecretFn({
      variables: {
        data: {
          nonce: nonce,
          stripeCardId: cardDetailsObj.cardId as string,
        },
      },
    });
  };

  const onHide = () => {
    if (cardDetailsObj?.cardType === ENUM_CARD_TYPE.PHYSICAL) {
      pin?.destroy();
      setPin(null);
    }
    if (cardDetailsObj?.cardType === ENUM_CARD_TYPE.VIRTUAL) {
      number?.destroy();
      setNumber(null);
      cvv?.destroy();
      setCvv(null);
      expiry?.destroy();
      setExpiry(null);
    }
  };

  return (
    <div className="flex flex-col gap-4 overflow-hidden md:gap-8 ">
      {cardDetailsObj?.cardType === ENUM_CARD_TYPE.PHYSICAL && (
        <div className="w-full text-sm font-medium text-left md:text-base">
          <div className="bg-gray-700 rounded-md">
            <div className="flex items-center justify-start p-4 pb-2 text-white">
              <LifebuoyIcon
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
              />
              <span className="ml-2">Help Guide</span>
            </div>
            <p className="px-4 pb-4 text-xs font-medium text-white md:text-sm">
              Pins are applicable only to physical cards, not virtual cards. It
              should be 4 digits. Any merchant may ask for the pin for any
              transaction. When that happens, if the pin is entered wrongly 3
              times, the card will be made inactive immediately. When a card is
              ordered, the default pin is set to 0000.
            </p>
          </div>
        </div>
      )}
      <div className="py-4 text-sm bg-white rounded-md shadow md:py-8 md:text-base">
        {cardDetailsObj.cardType === ENUM_CARD_TYPE.VIRTUAL && (
          <div className="flex flex-col gap-4 overflow-hidden md:gap-8 ">
            <div className="flex gap-4 px-4 font-medium">
              <div className="font-semibold text-brown-500 md:text-lg">
                Virtual Card
              </div>
              {number && !cardRevealing ? (
                <Button
                  variant={ButtonVariant.Transparent}
                  className="!p-0 text-orange-500 flex gap-1"
                  onClick={onHide}
                >
                  <LockClosedIcon className="w-4 h-4"></LockClosedIcon>
                  Hide
                </Button>
              ) : (
                <Button
                  disabled={!stripe || cardRevealing}
                  variant={ButtonVariant.Transparent}
                  className="!p-0 text-orange-500 flex gap-1"
                  onClick={onReveal}
                >
                  <EyeIcon className="w-4 h-4"></EyeIcon>
                  Reveal
                </Button>
              )}
            </div>
            <div className="h-0.5 border-0 bg-brown-500"></div>

            <div className="flex flex-row items-center w-full px-4">
              <div className="w-full">
                <div
                  style={{
                    background:
                      "linear-gradient(130deg,#29120A 0, #823920 100%)",
                    transform: "translate3d(0, 0, 0)",
                  }}
                  className=" rounded-xl backdrop-blur-md w-full  max-w-[400px] h-60 py-4 px-4 md:px-8 text-white"
                >
                  <div className="flex justify-between w-full">
                    <p className="text-bold">
                      <b>ROADFLEX</b>
                    </p>
                  </div>
                  <div className="my-2 max-w-[50px]">
                    <Image
                      layout="responsive"
                      width={"50"}
                      height={"50"}
                      src={`${NEXT_PUBLIC_CDN_URL}/svg/card-chip.svg`}
                      alt="card-chip"
                    ></Image>
                  </div>
                  <label className="text-xs uppercase">Card Number</label>
                  <label
                    id="card-number"
                    className={cardRevealing ? "hidden" : ""}
                  ></label>
                  {!number && !cardRevealing && (
                    <div className="flex w-full text-2xl text-bold drop-shadow-2xl">
                      <div id="number-1" style={{ marginRight: "4px" }}>
                        ****
                      </div>
                      <div id="number-2" style={{ marginRight: "4px" }}>
                        ****
                      </div>
                      <div id="number-3" style={{ marginRight: "4px" }}>
                        ****
                      </div>
                      <div id="number-4">{cardDetailsObj.lastFour}</div>
                    </div>
                  )}
                  {cardRevealing && (
                    <div className="flex space-x-4 animate-pulse">
                      <div className="flex-1 py-1 space-y-6">
                        <div className="space-y-3">
                          <div className="grid grid-cols-6 gap-4">
                            <div className="h-4 col-span-1 bg-slate-200 rounded-xl"></div>
                            <div className="h-4 col-span-1 bg-slate-200 rounded-xl"></div>
                            <div className="h-4 col-span-1 bg-slate-200 rounded-xl"></div>
                            <div className="h-4 col-span-1 bg-slate-200 rounded-xl"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-between mt-2">
                    <div className="flex gap-8">
                      <div>
                        <label className="text-xs uppercase">Valid Till</label>
                        <div
                          id="expiry"
                          className={cardRevealing ? "hidden" : ""}
                        ></div>
                        {!expiry && !cardRevealing && (
                          <p className="text-xl">**/**</p>
                        )}
                        {cardRevealing && (
                          <div className="flex space-x-4 animate-pulse">
                            <div className="flex-1 py-1 space-y-6">
                              <div className="space-y-3">
                                <div className="">
                                  <div className="h-4 bg-slate-200 rounded-xl w-[65px] col-span-1"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <label className="text-xs uppercase">CVV</label>
                        <div
                          id="cvv"
                          className={classNames(
                            cardRevealing ? "hidden" : "",
                            "min-w-[45px]",
                          )}
                        ></div>
                        {!cvv && !cardRevealing && (
                          <p className="text-xl">***</p>
                        )}
                        {cardRevealing && (
                          <div className="flex space-x-4 animate-pulse">
                            <div className="flex-1 py-1 space-y-6">
                              <div className="space-y-3">
                                <div className="grid grid-cols-6 gap-4">
                                  <div className="h-4 bg-slate-200  w-[45px] rounded-xl col-span-1"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex absolute right-[32px] bottom-4">
                      <Image
                        layout="fixed"
                        width={"70"}
                        height={"50"}
                        src={`${NEXT_PUBLIC_CDN_URL}/svg/visa-signature.svg`}
                        alt="card-chip"
                      ></Image>
                      {/* <div className="absolute z-10 w-8 h-8 bg-red-500 rounded-full right-5 top-1"></div>
                            <div className="absolute right-0 w-8 h-8 rounded-full bg-amber-500 top-1"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {cardDetailsObj?.cardType === ENUM_CARD_TYPE.PHYSICAL && (
          <div className="flex flex-col gap-4 overflow-hidden md:gap-8">
            <div className="flex gap-4 px-4 font-medium">
              <div className="font-semibold text-brown-500 md:text-lg">
                Physical Card
              </div>
              {pin && !cardRevealing ? (
                <Button
                  variant={ButtonVariant.Transparent}
                  className="!p-0 text-orange-500 flex gap-1"
                  onClick={onHide}
                >
                  <LockClosedIcon className="w-4 h-4"></LockClosedIcon>
                  Hide
                </Button>
              ) : (
                <Button
                  disabled={!stripe || cardRevealing}
                  variant={ButtonVariant.Transparent}
                  className="!p-0 text-orange-500 flex gap-1"
                  onClick={onReveal}
                >
                  <EyeIcon className="w-4 h-4"></EyeIcon>
                  Reveal
                </Button>
              )}
            </div>
            <div className="h-0.5 border-0 bg-brown-500"></div>
            <div className="flex flex-row items-center w-full px-4">
              <div className="w-full">
                <label className="uppercase">Card Pin</label>
                <div
                  id="card-pin"
                  className={classNames(
                    cardRevealing ? "hidden" : "",
                    "min-w-[45px]",
                  )}
                ></div>
                {!pin && !cardRevealing && (
                  <p className="text-xl text-brown-500">****</p>
                )}
                {cardRevealing && (
                  <div className="flex space-x-4 animate-pulse">
                    <div className="flex-1 py-1 space-y-6">
                      <div className="space-y-3">
                        <div className="grid grid-cols-6 gap-4">
                          <div className="h-4 bg-slate-200  w-[45px] rounded-xl col-span-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center">
              <div className="px-4">Update Pin</div>
              <div className="relative w-full sm:max-w-[250px] lg:max-w-md">
                <TextInput
                  type="text" //cannot be number type, otherwise, it will interpret 0123 as a 3-digit number due to preceeding 0
                  name="pin"
                  value={cardPinValues.pin}
                  onChange={cardPinHandleChange}
                  onBlur={cardPinHandleBlur}
                  error={cardPinTouched.pin && Boolean(cardPinErrors.pin)}
                  errorMessage={cardPinErrors.pin}
                  touched={cardPinTouched.pin}
                  variant="medium"
                  placeholder="Enter your new 4-digit pin"
                  maxLength={4}
                  minLength={4}
                ></TextInput>
              </div>
              <div className="ml-4">
                <FuelCardComponentPermissionGuard
                  type="button"
                  featurePermission={access}
                  messageType={"enterprise"}
                >
                  <Button
                    variant={ButtonVariant.AppOrange}
                    size={ButtonSize.AppSize}
                    disabled={
                      cardPinIsSubmitting ||
                      cardPinErrors.pin !== undefined ||
                      cardPinErrors.cardId !== undefined
                    }
                    onClick={() => cardPinHandleSubmit()}
                  >
                    Update
                  </Button>
                </FuelCardComponentPermissionGuard>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
