/** @format */

import { useMutation } from "@apollo/client";
import {
  ChevronRightIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import {
  READ_TRANSACTION_LIST_PAGINATION,
  WAIVE_TRANSACTION_RECEIPT_REQUIREMENT,
} from "@roadflex/graphql";
import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  CardsFilterOptions,
  DriversFilterOptions,
  TabsType,
  TransactionType,
  UserAuthType,
  VehiclesFilterOptions,
} from "@roadflex/types";
import classNames from "classnames";
import { useRouter } from "next/router";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import {
  DataTable,
  DataTablePFSEvent,
  DataTableRowExpansionTemplate,
  DataTableRowToggleParams,
} from "primereact/datatable";
import { Menu } from "primereact/menu";
import { createRef, useRef, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../../buttons";
import { dateFilterTemplate } from "../../../datatable";
import {
  dateTemplate,
  driverFilterTemplate,
  vehicleFilterTemplate,
} from "../../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";
import {
  cardFilterTemplate,
  cardLastFourTemplate,
  driverTemplate,
  stateTemplate,
  transactionAmountTemplate,
  transactionsActionTemplate,
  vehicleTemplate,
} from "../../../datatable/transaction-table-templates/table-templates";
import { Toast } from "../../../toast-message/toast";

type TransactionsReceiptRequireUIProps = {
  receiptRequiredTransactionData: {
    transactions: TransactionType[];
    totalCount: number;
  };
  transactionFilterOptions: {
    vehiclesFilterOptions: VehiclesFilterOptions[];
    cardsFilterOptions: CardsFilterOptions[];
    driversFilterOptions: DriversFilterOptions[];
  };
  receiptRequiredTransactionsLazyParams: DataTablePFSEvent;
  setReceiptRequiredTransactionsLazyParams: (value: DataTablePFSEvent) => void;
  transactionLoading: boolean;
  tabs: TabsType[];
  tabOpen: number;
  setTabOpen: (value: number) => void;
  readCurrentUserAuth: UserAuthType;
};

const dateFilterApplied = (e: unknown) => {
  const event = e as {
    field: string;
    constraints: {
      operator: FilterOperator;
      constraints: [
        { value: null | string | Date; matchMode: FilterMatchMode },
      ];
    };
  };
};

const TransactionsReceiptRequireUI = ({
  receiptRequiredTransactionData,
  transactionFilterOptions,
  receiptRequiredTransactionsLazyParams,
  setReceiptRequiredTransactionsLazyParams,
  transactionLoading,
  tabs,
  tabOpen,
  setTabOpen,
  readCurrentUserAuth,
}: TransactionsReceiptRequireUIProps) => {
  const transactionRef = useRef<DataTable>(null);
  const { isMobile } = usePlatformDetect();
  const router = useRouter();
  const backToTransactionsPanel = () => {
    router.push("/transactions");
  };
  const [expandedRows, setExpandedRows] = useState<TransactionType[]>([]);
  const transactionRefs = useRef<(React.RefObject<Menu> | null)[]>([]);
  transactionRefs.current = receiptRequiredTransactionData.transactions.map(
    (_, i) => transactionRefs.current[i] ?? createRef(),
  );

  const [waiveRequirementFn] = useMutation<{
    waiveTransactionReceiptRequirement: {
      code: string;
      message: string;
    };
  }>(WAIVE_TRANSACTION_RECEIPT_REQUIREMENT, {
    refetchQueries: [READ_TRANSACTION_LIST_PAGINATION],
  });

  const waiveRequirement = (transactionId: string) => {
    waiveRequirementFn({
      variables: {
        data: { transactionId },
      },
    })
      .then((res) => {
        if (res?.data?.waiveTransactionReceiptRequirement?.code === "200") {
          Toast({
            type: "success",
            message: "Receipt requirement waived successfully",
          });
        } else {
          Toast({
            type: "error",
            message:
              res?.data?.waiveTransactionReceiptRequirement?.message || "",
          });
        }
      })
      .catch((err: unknown) => {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        } else {
          Toast({ type: "error", message: "Something went wrong" });
        }
      });
  };
  const rowExpansionTransactionTemplate = (
    data: TransactionType,
    options: DataTableRowExpansionTemplate,
    waiveButton = false,
  ) => {
    return (
      <div className="max-w-sm p-4 mx-auto bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="mb-4 font-semibold text-gray-800">
          {data.merchantName} {/* Merchant Name */}
        </div>
        <div className="flex flex-col gap-2 text-sm text-gray-600">
          <div>
            Card: {cardLastFourTemplate(data)}
            {/* Last four digits of the card used */}
          </div>
          <div>
            Driver: {driverTemplate(data)} {/* Driver name */}
          </div>
          <div>
            Vehicle: {vehicleTemplate(data)} {/* Vehicle detail */}
          </div>
          <div>
            Location: {stateTemplate(data)} {/* Merchant location */}
          </div>

          <div className="flex items-center">
            More Details:{transactionsActionTemplate(data)}
          </div>
          {waiveButton && (
            <Button
              variant={ButtonVariant.AppOrange}
              className="!p-0"
              onClick={(event) => waiveRequirement(data.id)}
            >
              Waive Receipt Requirement
            </Button>
          )}
        </div>
      </div>
    );
  };
  const onPageAndSortTransactions = (event: DataTablePFSEvent) => {
    setReceiptRequiredTransactionsLazyParams(event);
  };

  const onFilterTransactions = (event: DataTablePFSEvent) => {
    event.first = 0; // eslint-disable-line no-param-reassign
    setReceiptRequiredTransactionsLazyParams(event);
  };

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="flex flex-col w-full rounded-md md:my-12">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row">
            <Button
              size={ButtonSize.Small}
              variant={ButtonVariant.GrayOutline}
              onClick={backToTransactionsPanel}
              className="pl-0 ml-[-6px] border-0"
            >
              <span className="flex items-center h-5">
                <ChevronRightIcon
                  className={
                    "-rotate-180 h-5 w-5 transform  ease-in duration-300"
                  }
                  aria-hidden="true"
                />
              </span>
              Back
            </Button>
            <div className="ml-4 text-lg font-semibold">
              Transaction Receipt Required
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 mt-5 overflow-hidden bg-white rounded-md shadow md:p-8 md:mt-0">
        <div className="border-b border-brown-500">
          <nav className="flex -mb-px space-x-4 md:space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tabOpen === tab.id
                    ? "border-black text-black"
                    : "border-transparent hover:border-black",
                  "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                )}
                onClick={(e) => {
                  e.preventDefault();
                  setTabOpen(tab.id);
                }}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
        <div className={`${tabOpen === 1 ? `block` : `hidden`}`}>
          <div className="flex-col h-full mt-4">
            {/* <Table data={transactionList} rowsPerPage={10} /> */}
            <DataTable
              pageLinkSize={isMobile() ? 2 : 3}
              size="small"
              ref={transactionRef}
              stripedRows
              value={receiptRequiredTransactionData.transactions}
              loading={transactionLoading}
              paginator
              dataKey="id"
              paginatorTemplate={newPaginationTemplate}
              responsiveLayout={isMobile() ? "scroll" : "scroll"}
              emptyMessage="No available records"
              className="w-auto min-w-full custom-table"
              style={{
                fontFamily: "Inter",
              }}
              lazy
              rows={receiptRequiredTransactionsLazyParams.rows}
              filters={receiptRequiredTransactionsLazyParams.filters}
              sortField={receiptRequiredTransactionsLazyParams.sortField}
              sortOrder={receiptRequiredTransactionsLazyParams.sortOrder}
              sortMode={"single"}
              first={receiptRequiredTransactionsLazyParams.first}
              totalRecords={receiptRequiredTransactionData.totalCount}
              onPage={onPageAndSortTransactions}
              onSort={onPageAndSortTransactions}
              onFilter={onFilterTransactions}
              rowExpansionTemplate={(e, f) =>
                rowExpansionTransactionTemplate(e, f, true)
              }
              expandedRows={expandedRows}
              onRowToggle={(e: DataTableRowToggleParams) =>
                setExpandedRows(e.data as TransactionType[])
              }
            >
              <Column
                filter
                filterElement={dateFilterTemplate}
                sortable
                dataType="date"
                filterField="date"
                field="date"
                header={`Date (${readCurrentUserAuth?.accountDisplaySetting?.timeZone})`}
                body={(r) =>
                  dateTemplate(
                    r.authorizationDate,
                    readCurrentUserAuth?.accountDisplaySetting?.timeZone,
                  )
                }
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                onFilterApplyClick={dateFilterApplied}
              />
              {!isMobile() && (
                <Column
                  // filter
                  sortable
                  filterPlaceholder="Search by Name"
                  field="merchantName"
                  header="Merchant"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  // filter
                  sortable
                  filterPlaceholder="Search by State"
                  sortField="merchantState"
                  header="Location"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={stateTemplate}
                />
              )}
              {!isMobile() && (
                <Column
                  showFilterMatchModes={false}
                  filterMenuStyle={{
                    width: "14rem",
                  }}
                  filter
                  sortable
                  header="Card"
                  field="lastFour"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  filterMenuClassName="custom-filter"
                  filterElement={(e) =>
                    cardFilterTemplate(
                      e,
                      transactionFilterOptions.cardsFilterOptions,
                    )
                  }
                  body={cardLastFourTemplate}
                />
              )}
              {!isMobile() && (
                <Column
                  showFilterMatchModes={false}
                  filterMenuStyle={{
                    width: "14rem",
                  }}
                  filter
                  sortable
                  body={driverTemplate}
                  header="Driver"
                  field="driver"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  filterMenuClassName="custom-filter"
                  filterElement={(e) =>
                    driverFilterTemplate(
                      e,
                      transactionFilterOptions.driversFilterOptions,
                    )
                  }
                />
              )}
              {!isMobile() && (
                <Column
                  showFilterMatchModes={false}
                  filterMenuStyle={{
                    width: "14rem",
                  }}
                  filter
                  sortable
                  body={vehicleTemplate}
                  header="Vehicle"
                  field="vehicle"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  filterMenuClassName="custom-filter"
                  filterElement={(e) =>
                    vehicleFilterTemplate(
                      e,
                      transactionFilterOptions.vehiclesFilterOptions,
                    )
                  }
                />
              )}
              <Column
                // filter
                sortable
                field="transactionAmount"
                header="Amount"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={transactionAmountTemplate}
              />
              {!isMobile() && (
                <Column
                  // filter
                  field="details"
                  header="Details"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={transactionsActionTemplate}
                />
              )}
              {!isMobile() && (
                <Column
                  headerStyle={{ width: "3em" }}
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base step-3"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(e, props) => (
                    <div className="relative flex">
                      <Menu
                        model={[
                          {
                            label: "Remove receipt requirement",
                            icon: "pi pi-fw pi-times",
                            command: () => {
                              waiveRequirement(e.id);
                            },
                          },
                        ]}
                        popup
                        className="whitespace-nowrap !w-72"
                        ref={transactionRefs.current[props.rowIndex]}
                      />
                      <Button
                        variant={ButtonVariant.Transparent}
                        className="!p-0"
                        onClick={(event) =>
                          transactionRefs.current[
                            props.rowIndex
                          ]?.current?.toggle(event)
                        }
                      >
                        <EllipsisVerticalIcon className="w-5"></EllipsisVerticalIcon>
                      </Button>
                    </div>
                  )}
                ></Column>
              )}
              {isMobile() && (
                <Column
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  expander
                  style={{ width: "3em" }}
                />
              )}
            </DataTable>
          </div>
        </div>
        <div className={`${tabOpen === 2 ? `block` : `hidden`}`}>
          <div className="flex-col h-full mt-4">
            {/* <Table data={transactionList} rowsPerPage={10} /> */}
            <DataTable
              pageLinkSize={isMobile() ? 2 : 3}
              size="small"
              ref={transactionRef}
              stripedRows
              value={receiptRequiredTransactionData.transactions}
              loading={transactionLoading}
              paginator
              dataKey="id"
              paginatorTemplate={newPaginationTemplate}
              responsiveLayout={isMobile() ? "scroll" : "scroll"}
              emptyMessage="No available records"
              className="w-auto min-w-full custom-table"
              style={{
                fontFamily: "Inter",
              }}
              lazy
              rows={receiptRequiredTransactionsLazyParams.rows}
              filters={receiptRequiredTransactionsLazyParams.filters}
              sortField={receiptRequiredTransactionsLazyParams.sortField}
              sortOrder={receiptRequiredTransactionsLazyParams.sortOrder}
              sortMode={"single"}
              first={receiptRequiredTransactionsLazyParams.first}
              totalRecords={receiptRequiredTransactionData.totalCount}
              onPage={onPageAndSortTransactions}
              onSort={onPageAndSortTransactions}
              onFilter={onFilterTransactions}
              rowExpansionTemplate={rowExpansionTransactionTemplate}
              expandedRows={expandedRows}
              onRowToggle={(e: DataTableRowToggleParams) =>
                setExpandedRows(e.data as TransactionType[])
              }
            >
              <Column
                filter
                filterElement={dateFilterTemplate}
                sortable
                dataType="date"
                filterField="date"
                field="date"
                header={`Date (${readCurrentUserAuth?.accountDisplaySetting?.timeZone})`}
                body={(r) =>
                  dateTemplate(
                    r.authorizationDate,
                    readCurrentUserAuth?.accountDisplaySetting?.timeZone,
                  )
                }
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                onFilterApplyClick={dateFilterApplied}
              />
              {!isMobile() && (
                <Column
                  // filter
                  sortable
                  filterPlaceholder="Search by Name"
                  field="merchantName"
                  header="Merchant"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
              )}
              {!isMobile() && (
                <Column
                  // filter
                  sortable
                  filterPlaceholder="Search by State"
                  sortField="merchantState"
                  header="Location"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={stateTemplate}
                />
              )}
              {!isMobile() && (
                <Column
                  showFilterMatchModes={false}
                  filterMenuStyle={{
                    width: "14rem",
                  }}
                  filter
                  sortable
                  header="Card"
                  field="lastFour"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  filterMenuClassName="custom-filter"
                  filterElement={(e) =>
                    cardFilterTemplate(
                      e,
                      transactionFilterOptions.cardsFilterOptions,
                    )
                  }
                  body={cardLastFourTemplate}
                />
              )}
              {!isMobile() && (
                <Column
                  showFilterMatchModes={false}
                  filterMenuStyle={{
                    width: "14rem",
                  }}
                  filter
                  sortable
                  body={driverTemplate}
                  header="Driver"
                  field="driver"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  filterMenuClassName="custom-filter"
                  filterElement={(e) =>
                    driverFilterTemplate(
                      e,
                      transactionFilterOptions.driversFilterOptions,
                    )
                  }
                />
              )}
              {!isMobile() && (
                <Column
                  showFilterMatchModes={false}
                  filterMenuStyle={{
                    width: "14rem",
                  }}
                  filter
                  sortable
                  body={vehicleTemplate}
                  header="Vehicle"
                  field="vehicle"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  filterMenuClassName="custom-filter"
                  filterElement={(e) =>
                    vehicleFilterTemplate(
                      e,
                      transactionFilterOptions.vehiclesFilterOptions,
                    )
                  }
                />
              )}
              <Column
                // filter
                sortable
                field="transactionAmount"
                header="Amount"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={transactionAmountTemplate}
              />
              {!isMobile() && (
                <Column
                  // filter
                  field="details"
                  header="Details"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={transactionsActionTemplate}
                />
              )}
              {isMobile() && (
                <Column
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  expander
                  style={{ width: "3em" }}
                />
              )}
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsReceiptRequireUI;
