/** @format */
import { FeatureType, UserAuthType, UserDetailsType } from "@roadflex/types";
import classNames from "classnames";
import { NoAccessFeatureUI } from "../no-access";
import Details from "./account-details/details";
import ActivityLogs from "./activity-logs/activity-logs";
import DisplaySettings from "./display-settings/display-settings";
import UpdateAddresses from "./profile/update-address";
import UpdateBasic from "./profile/update-basic";
import UpdateBusiness from "./profile/update-business";
import UpdateFleet from "./profile/update-fleet-info";
import UpdatePassword from "./profile/update-password";

type AccountSettingsUIProps = {
  readCurrentUserAuth: UserAuthType;
  readUserDetailsData: UserDetailsType;
  childFeatures: FeatureType[];
  tabOpen: number;
  setTabOpen: (value: number) => void;
};

export const AccountSettingsUI = ({
  readCurrentUserAuth,
  readUserDetailsData,
  childFeatures,
  tabOpen,
  setTabOpen,
}: AccountSettingsUIProps) => {
  const tabs = [
    { id: 1, name: "Profile", href: "", current: true },
    { id: 2, name: "Account Details", href: "", current: false },
    { id: 3, name: "Activity Logs", href: "", current: false },
    { id: 4, name: "Display Settings", href: "", current: false },
  ];
  const AccountDetailAccess =
    childFeatures.find(
      (item) => item.name === "ACCOUNT_SETTING_PAGE_ACCOUNT_DETAILS",
    ) !== undefined;
  const ActivityLogsAccess =
    childFeatures.find(
      (item) => item.name === "ACCOUNT_SETTING_PAGE_ACTIVITY_LOGS",
    ) !== undefined;

  const DisplaySettingsAccess =
    childFeatures.find(
      (item) => item.name === "ACCOUNT_SETTING_PAGE_DISPLAY",
    ) !== undefined;

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Account Settings`.toUpperCase()}
      </div>
      <div>
        <div className="mt-4 text-sm md:text-base md:mt-0">
          <div className="mb-8 border-b border-brown-500">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-black text-black"
                      : "border-transparent hover:border-black",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setTabOpen(tab.id);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className={`${tabOpen === 1 ? `` : `hidden`} mt-4 md:mt-6`}>
        <div>
          <div className="" id="businessInfo">
            <UpdateBasic readUserDetailsData={readUserDetailsData} />
          </div>
          <div className="mt-4" id="businessInfo">
            <UpdateBusiness readUserDetailsData={readUserDetailsData} />
          </div>
          <div className="mt-4">
            <UpdateFleet
              readUserDetailsData={readUserDetailsData}
            ></UpdateFleet>
          </div>
          <div className="mt-4" id="address">
            <UpdateAddresses readUserDetailsData={readUserDetailsData} />
          </div>
          <div className="mt-4">
            <UpdatePassword />
          </div>
        </div>
      </div>
      <div className={`${tabOpen === 2 ? `` : `hidden`} mt-4 md:mt-6`}>
        <div>
          {AccountDetailAccess && (
            <Details
              readCurrentUserAuth={readCurrentUserAuth}
              readUserDetailsData={readUserDetailsData}
            />
          )}
          {!AccountDetailAccess && <NoAccessFeatureUI />}
        </div>
      </div>
      <div className={`${tabOpen === 3 ? `` : `hidden`} mt-4 md:mt-6`}>
        <div>
          {ActivityLogsAccess && readCurrentUserAuth.accountDisplaySetting && (
            <ActivityLogs
              readAccountDisplaySetting={
                readCurrentUserAuth?.accountDisplaySetting
              }
            />
          )}
          {!ActivityLogsAccess && <NoAccessFeatureUI />}
        </div>
      </div>
      <div className={`${tabOpen === 4 ? `` : `hidden`} mt-4 md:mt-6`}>
        <div>
          {DisplaySettingsAccess &&
            readCurrentUserAuth.accountDisplaySetting && (
              <DisplaySettings
                readAccountDisplaySetting={
                  readCurrentUserAuth?.accountDisplaySetting
                }
              />
            )}
          {!DisplaySettingsAccess && <NoAccessFeatureUI />}
        </div>
      </div>
    </div>
  );
};
