/** @format */

import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { ENUM_JOB_TITLE } from "@prisma/client";
import { STATE_CODES } from "@roadflex/constants";
import {
  OwnerAndExecutiveType,
  OwnersAndExecutivesValues,
} from "@roadflex/types";
import { humanizeEnum } from "@roadflex/utils";
import {
  dateOfBirthFormatter,
  phoneFormatter,
  ssnFormatter,
} from "@roadflex/web-lib";
import classNames from "classnames";
import { FormikProps } from "formik";
import { forwardRef, LegacyRef, Ref } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { Select } from "../dropdowns";
import { TextInput } from "../inputs";

interface BeneficialOwnersUIProps
  extends FormikProps<OwnersAndExecutivesValues> {
  isSubmittingOnboardStatus: boolean;
  ownersAndExecutives: OwnerAndExecutiveType[];
  onEdit: (ownerAndExecutive: OwnerAndExecutiveType) => void;
  showInputs: boolean;
  onAddNewOwner: () => void;
  nextStep: (jumpStepBy?: number) => void;
  onPreviousStep: () => void;
  setShowInputs: (value: boolean) => void;
  removingOwnerAndExecutive: boolean;
  onRemoveOwner: (id: string) => void;
}

const BeneficialOwnersUI = forwardRef(
  (
    {
      values,
      handleChange,
      handleSubmit,
      setFieldValue,
      setFieldTouched,
      isSubmitting,
      handleBlur,
      errors,
      touched,
      isSubmittingOnboardStatus,
      dirty,
      ownersAndExecutives,
      onEdit,
      showInputs,
      onAddNewOwner,
      nextStep,
      onPreviousStep,
      setShowInputs,
      removingOwnerAndExecutive,
      onRemoveOwner,
      ...rest
    }: BeneficialOwnersUIProps,
    autoCompleteRef: unknown,
  ) => {
    const { homeAddressAutoCompleteRef = null } = (
      autoCompleteRef as { current: unknown }
    )?.current as {
      homeAddressAutoCompleteRef: LegacyRef<HTMLInputElement>;
    };
    return (
      <form onSubmit={handleSubmit} className="flex flex-col w-full ">
        <div className="mb-1 text-xl font-semibold text-center text-white md:mb-4 md:text-4xl">
          {!showInputs
            ? "Review your business owners"
            : values.id
            ? "Edit business owner details"
            : "Add business owner details"}
        </div>
        <div className="mb-4 text-xs text-center text-white md:mb-8 md:text-sm">
          Due to regulatory guidelines, we are required to collect information
          on anyone who has significant ownership (&#62;25&#37;) of your
          business.
        </div>
        <div className={classNames(showInputs ? "hidden" : "")}>
          <div className="w-full space-y-2">
            {ownersAndExecutives.map((ownerAndExecutive, idx) => (
              <div key={ownerAndExecutive.id}>
                <div className="flex flex-row items-center justify-between">
                  <div className="text-white">Owner {idx + 1}</div>
                  <div className="flex items-center space-x-3">
                    <Button
                      variant={ButtonVariant.Transparent}
                      className="underline-offset-2 underline text-white !px-0"
                      onClick={() => {
                        onEdit(ownerAndExecutive);
                      }}
                    >
                      Edit
                    </Button>
                    {!ownerAndExecutive.representative && (
                      <Button
                        variant={ButtonVariant.Transparent}
                        className="underline-offset-2 underline text-white !px-0"
                        disabled={removingOwnerAndExecutive}
                        onClick={() => {
                          onRemoveOwner(ownerAndExecutive.id);
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </div>
                <div className="w-full p-4 text-sm bg-white rounded-md ">
                  <span>
                    {ownerAndExecutive.firstName} {ownerAndExecutive.lastName}
                  </span>{" "}
                  <br />
                  {ownerAndExecutive.address?.streetAddress}, <br />
                  {ownerAndExecutive.address?.streetDetails && (
                    <div>{ownerAndExecutive.address?.streetDetails},</div>
                  )}
                  {ownerAndExecutive.address?.city},{" "}
                  {ownerAndExecutive.address?.state}, <br />
                  {ownerAndExecutive.address?.zip}
                </div>
              </div>
            ))}
          </div>
          <div>
            <Button
              variant={ButtonVariant.Transparent}
              className="underline-offset-2 underline text-white !px-0"
              onClick={() => {
                //   onJumpToStep(2);
                onAddNewOwner();
              }}
            >
              Add another owner
            </Button>
          </div>
          <div className="mt-6">
            <Button
              variant={ButtonVariant.Orange}
              className="w-full"
              onClick={() => {
                nextStep();
              }}
              disabled={isSubmittingOnboardStatus}
              loading={isSubmittingOnboardStatus}
            >
              <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>
              Next
            </Button>
            <div>
              <div className="flex items-center justify-center mt-1 text-sm text-center text-white">
                <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
                We securely store and encrypt your personal information
              </div>
              <Button
                type={ButtonType.Button}
                variant={ButtonVariant.Transparent}
                className="pl-0 text-white"
                size={ButtonSize.Small}
                onClick={onPreviousStep}
              >
                &#60;&#60; Go back
              </Button>
            </div>
          </div>
        </div>
        <div className={classNames(showInputs ? "" : "hidden")}>
          <div className="relative w-full">
            <TextInput
              label="Legal First (and Middle) Name"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && Boolean(errors.firstName)}
              errorMessage={errors.firstName}
              touched={touched.firstName}
              variant="responsive"
              required
              color={"text-white"}
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label="Legal Last Name"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && Boolean(errors.lastName)}
              errorMessage={errors.lastName}
              touched={touched.lastName}
              variant="responsive"
              required
              color={"text-white"}
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label="Email Address"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              errorMessage={errors.email}
              touched={touched.email}
              variant="responsive"
              required
              color={"text-white"}
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label="Mobile"
              type="tel"
              name="phoneNumber"
              value={values.phoneNumber}
              onChange={(event) =>
                setFieldValue(
                  "phoneNumber",
                  phoneFormatter(event?.target?.value),
                )
              }
              onBlur={handleBlur}
              error={touched.phoneNumber && Boolean(errors.phoneNumber)}
              errorMessage={errors.phoneNumber}
              touched={touched.phoneNumber}
              variant="responsive"
              required
              color={"text-white"}
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label={
                <div className="flex flex-row mb-1 text-white md:mb-2">
                  <div className="flex items-center ml-2 text-center whitespace-nowrap">
                    Date Of Birth*
                  </div>
                </div>
              }
              name="dateOfBirth"
              value={values.dateOfBirth}
              type="tel"
              onChange={(event) =>
                setFieldValue(
                  "dateOfBirth",
                  dateOfBirthFormatter(event?.target?.value),
                )
              }
              onBlur={handleBlur}
              autoComplete="dateOfBirth"
              error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
              errorMessage={errors.dateOfBirth}
              touched={touched.dateOfBirth}
              variant="responsive"
              placeholder="MM/DD/YYYY"
              required
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <Select
              label="Executive Job Title"
              value={values.executiveJobTitle}
              name="executiveJobTitle"
              options={[
                {
                  label: "-- Select an option --",
                  value: "",
                  disabled: true,
                },
              ].concat(
                Object.keys(ENUM_JOB_TITLE).map((key) => {
                  return {
                    label: humanizeEnum(key),
                    value: key,
                    disabled: false,
                  };
                }),
              )}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => setFieldValue("executiveJobTitle", e.value)}
              onBlur={() => setFieldTouched("executiveJobTitle", true)}
              placeholder="-- Select an option --"
              touched={touched.executiveJobTitle}
              error={
                touched.executiveJobTitle && Boolean(errors.executiveJobTitle)
              }
              errorMessage={errors.executiveJobTitle}
              required
              color="text-white"
              variant="responsive"
            ></Select>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label={
                <div className="mb-1 text-white md:mb-2">
                  <div className="flex flex-row ">
                    <div className="">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.45053 14.1161H20.8048C21.1895 14.1161 21.5584 14.2689 21.8304 14.5409C22.1025 14.813 22.2553 15.1819 22.2553 15.5666V26.5317C22.2553 27.1189 22.1395 27.7005 21.9146 28.243C21.6897 28.7855 21.36 29.2784 20.9444 29.6934C20.5289 30.1084 20.0356 30.4374 19.4928 30.6616C18.95 30.8858 18.3683 31.0008 17.781 31H5.46834C4.88155 31 4.3005 30.8844 3.75838 30.6599C3.21626 30.4353 2.72367 30.1062 2.30875 29.6913C1.89382 29.2763 1.56469 28.7837 1.34013 28.2416C1.11558 27.6995 1 27.1184 1 26.5317V15.5666C1 15.1819 1.15282 14.813 1.42485 14.5409C1.69688 14.2689 2.06582 14.1161 2.45053 14.1161V14.1161Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                        />
                        <path
                          d="M3.91504 13.8291V8.71768C3.91504 6.67082 4.72806 4.7078 6.1754 3.26046C7.62275 1.81311 9.58586 1 11.6327 1C13.6796 1 15.6426 1.81311 17.0899 3.26046C18.5373 4.7078 19.3504 6.67082 19.3504 8.71768V13.8351"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                        />
                        <path
                          d="M14.48 19.9973C14.4805 19.4846 14.3428 18.9813 14.0813 18.5403C13.8198 18.0993 13.4442 17.737 12.9941 17.4914C12.5441 17.2459 12.0361 17.1262 11.5238 17.1451C11.0114 17.1639 10.5136 17.3205 10.0828 17.5984C9.65196 17.8763 9.304 18.2652 9.07558 18.7242C8.84716 19.1832 8.74664 19.6953 8.78474 20.2066C8.82284 20.7179 8.99809 21.2094 9.29202 21.6295C9.58595 22.0496 9.98773 22.3827 10.455 22.5936V26.7948C10.4555 27.1059 10.5793 27.4041 10.7993 27.624C11.0192 27.844 11.3175 27.9678 11.6285 27.9683V27.9683C11.9398 27.9683 12.2382 27.8447 12.4582 27.6246C12.6783 27.4045 12.8019 27.1061 12.8019 26.7948V22.5936C13.302 22.3684 13.7263 22.0036 14.024 21.543C14.3217 21.0825 14.48 20.5457 14.48 19.9973V19.9973Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className="flex items-center ml-2 text-center whitespace-nowrap">
                      Executive's Social Security Number*
                    </div>
                  </div>
                </div>
              }
              name="ssn"
              value={values.ssn}
              type="tel"
              onChange={(event) =>
                setFieldValue("ssn", ssnFormatter(event?.target?.value))
              }
              onBlur={handleBlur}
              autoComplete="ssn"
              error={touched.ssn && Boolean(errors.ssn)}
              errorMessage={errors.ssn}
              touched={touched.ssn}
              variant="responsive"
              placeholder="###-##-####"
              required
            ></TextInput>
          </div>
          <div className="mt-2 text-sm text-white">
            To verify your identity, we'll need to know your full Social
            Security Number. We do not conduct credit checks. Your credit score
            won't be affected.
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label="Home Address* (PO Box not allowed)"
              name="homeAddress.streetAddress"
              ref={
                homeAddressAutoCompleteRef as unknown as Ref<HTMLInputElement>
              }
              value={values?.homeAddress?.streetAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={30}
              error={
                touched?.homeAddress?.streetAddress &&
                Boolean(errors?.homeAddress?.streetAddress)
              }
              errorMessage={errors?.homeAddress?.streetAddress}
              touched={touched?.homeAddress?.streetAddress}
              variant="responsive"
              informationToolTip="A valid business address is required. Any typos or wrong address can lead to lengthened review times."
              color="text-white"
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label="Suite # (Optional)"
              name="homeAddress.streetDetails"
              value={values?.homeAddress?.streetDetails}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={200}
              error={
                touched?.homeAddress?.streetDetails &&
                Boolean(errors?.homeAddress?.streetDetails)
              }
              errorMessage={errors?.homeAddress?.streetDetails}
              touched={touched?.homeAddress?.streetDetails}
              variant="responsive"
              color="text-white"
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label="City"
              name="homeAddress.city"
              value={values?.homeAddress?.city}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={15}
              error={
                touched?.homeAddress?.city && Boolean(errors?.homeAddress?.city)
              }
              errorMessage={errors?.homeAddress?.city}
              touched={touched?.homeAddress?.city}
              variant="responsive"
              color="text-white"
              required
            ></TextInput>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="relative w-full mt-4">
              <Select
                label="State"
                value={values?.homeAddress?.state}
                options={[
                  {
                    label: "-- Select a State--",
                    value: "",
                    disabled: true,
                  },
                ].concat(
                  STATE_CODES.map((m) => {
                    return {
                      label: `${m.label} (${m.value})`,
                      value: m.value,
                      disabled: false,
                    };
                  }),
                )}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => setFieldValue("homeAddress.state", e.value)}
                onBlur={() => setFieldTouched("homeAddress.state", true)}
                placeholder="Select"
                touched={touched?.homeAddress?.state}
                error={
                  touched?.homeAddress?.state &&
                  Boolean(errors?.homeAddress?.state)
                }
                errorMessage={errors?.homeAddress?.state}
                required
                variant="responsive"
                color="text-white"
              ></Select>
            </div>

            <div className="relative w-full mt-4 sm:ml-4">
              <TextInput
                label="Zip"
                name="homeAddress.zip"
                value={values?.homeAddress?.zip}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={5}
                error={
                  touched?.homeAddress?.zip && Boolean(errors?.homeAddress?.zip)
                }
                errorMessage={errors?.homeAddress?.zip}
                touched={touched?.homeAddress?.zip}
                variant="responsive"
                color="text-white"
                required
              ></TextInput>
            </div>
          </div>
          <div className="mt-6">
            <Button
              variant={ButtonVariant.Orange}
              className="w-full"
              onClick={() => {
                handleSubmit();
              }}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>
              {dirty ? `Save` : `Next`}
            </Button>
          </div>
          <div>
            <div className="flex items-center justify-center mt-1 text-sm text-center text-white">
              <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
              We securely store and encrypt your personal information
            </div>
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.Transparent}
              className="pl-0 text-white"
              size={ButtonSize.Small}
              onClick={() => setShowInputs(false)}
            >
              &#60;&#60; Go back
            </Button>
          </div>
        </div>
      </form>
    );
  },
);

export default BeneficialOwnersUI;
