/** @format */
import { UserOnboardType } from "@roadflex/types";
import AdditionalBusinessInfo from "./additional-business-information";
import BankVerification from "./bank-verification";
import BeneficialOwners from "./beneficial-owners";
import BusinessInformation from "./business-information";
import EmailVerification from "./email-verification";
import HomeAddress from "./home-address";
import PersonalInformation from "./personal-information";
import Review from "./review-stage";
import SetPassword from "./set-password";
import TermsOfService from "./terms-of-service";
export type FuelcardOnboardUIProps = {
  readCurrentUserOnboard: UserOnboardType;
  nextStep: (jumpStepBy?: number) => void;
  onBoardStatus: number;
  isSubmittingOnboardStatus: boolean;
  onPreviousStep: () => void;
  onJumpToStep: (step: number) => void;
  onSubmitApplication: () => void;
  isSubmittingApplication: boolean;
};

const FuelcardOnboardUI = ({
  readCurrentUserOnboard,
  nextStep,
  onBoardStatus,
  isSubmittingOnboardStatus,
  onSubmitApplication,
  onPreviousStep,
  onJumpToStep,
  isSubmittingApplication,
}: FuelcardOnboardUIProps) => {
  const list = [
    {
      title: "Email Verification",
      component: EmailVerification,
    },
    {
      title: "Set Password",
      component: SetPassword,
    },
    {
      title: "Business Info",
      component: BusinessInformation,
    },
    {
      title: "Additional Business Info",
      component: AdditionalBusinessInfo,
    },
    {
      title: "Personal Info",
      component: PersonalInformation,
    },
    {
      title: "Addi. Personal Info",
      component: HomeAddress,
    },
    {
      title: "Beneficial Owners",
      component: BeneficialOwners,
    },
    {
      title: "Your Finances",
      component: BankVerification,
    },
    {
      title: "Review",
      component: Review,
    },
    {
      title: "Terms of Service",
      component: TermsOfService,
    },
  ];
  return (
    <div className="w-full h-full min-h-[calc(100vh-128px)] flex flex-col justify-center">
      <div className="container flex flex-col items-center justify-center w-full h-full my-8">
        <div className="w-full md:w-max">
          <div className="mb-6 text-2xl font-semibold">Onboarding</div>
          <div
            className="p-4 sm:px-20 sm:py-16 rounded-xl min-h-[200px] md:min-h-[600px] w-full xl:w-[1250px] lg:w-[1000px] md:w-[750px]"
            style={{
              background: "linear-gradient(130deg,#29120A 0, #823920 100%)",
            }}
          >
            {/* {onBoardStatus > 1 && onBoardStatus < 7 && (
              <div className="flex items-center pb-10">
                {list.slice(2, 7).map(({ title }, idx) => (
                  <Fragment key={idx}>
                    <div className={classNames("relative flex items-center")}>
                      <div
                        className={classNames(
                          "w-9 h-9 py-3 transition duration-150 ease-in-out rounded-full text-xs sm:text-sm md:text-base flex items-center justify-center",
                          onBoardStatus - 2 === idx
                            ? "bg-orange-500 text-white"
                            : "bg-white",
                        )}
                      >
                        {idx + 1}
                      </div>
                      <div
                        className={classNames(
                          "absolute top-0 md:w-20 lg:w-32 mt-12 ml-[-0.25rem] md:-ml-5 lg:-ml-[47px] text-xs sm:text-sm text-center text-white uppercase",
                        )}
                      >
                        {title}
                      </div>
                    </div>
                    {idx >= 0 && idx <= 3 ? (
                      <div
                        className={classNames(
                          "flex-auto transition duration-500 ease-in-out border-t-4 border-orange-500",
                        )}
                      />
                    ) : null}
                  </Fragment>
                ))}
              </div>
            )} */}
            <div className="flex items-center justify-center min-h-[245px]">
              {list.map(({ title, component: Component }, idx) => {
                if (onBoardStatus === idx) {
                  return (
                    <div
                      key={idx}
                      className="flex flex-col justify-between flex-grow-1 h-full w-full min-h-[245px]"
                    >
                      <div>
                        <Component
                          {...{
                            title,
                            nextStep,
                            readCurrentUserOnboard,
                            onJumpToStep,
                            onSubmitApplication,
                            isSubmittingOnboardStatus,
                            isSubmittingApplication,
                            onPreviousStep,
                          }}
                        />
                      </div>
                    </div>
                  );
                }

                return null;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuelcardOnboardUI;
