/** @format */

import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { FLEET_TYPES, STATE_CODES } from "@roadflex/constants";
import {
  AdditionalBusinessInfoType,
  AdditionalBusinessInfoValues,
} from "@roadflex/types";
import { fuelFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import { FormikProps } from "formik";
import { forwardRef, LegacyRef, Ref } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { Select } from "../dropdowns";
import { TextInput } from "../inputs";

interface AdditionalBusinessInfoUIProps
  extends FormikProps<AdditionalBusinessInfoValues> {
  isSubmittingOnboardStatus: boolean;
  additionalBusinessInfo: AdditionalBusinessInfoType;
  onPreviousStep: () => void;
}

const AdditionalBusinessInfoUI = forwardRef(
  (
    {
      values,
      handleChange,
      handleSubmit,
      handleBlur,
      isSubmitting,
      errors,
      touched,
      isSubmittingOnboardStatus,
      setFieldValue,
      setFieldTouched,
      dirty,
      onPreviousStep,
      ...rest
    }: AdditionalBusinessInfoUIProps,
    autoCompleteRef: unknown,
  ) => {
    const { businessAutoCompleteRef = null, shippingAutoCompleteRef = null } = (
      autoCompleteRef as { current: unknown }
    )?.current as {
      businessAutoCompleteRef: LegacyRef<HTMLInputElement>;
      shippingAutoCompleteRef: LegacyRef<HTMLInputElement>;
    };
    return (
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
        <div className="mb-1 text-xl font-semibold text-center text-white md:mb-4 md:text-4xl">
          Tell us more about your business
        </div>
        <div className="mb-4 text-xs text-center text-white md:mb-8 md:text-sm">
          We need some more details to verify your business.
        </div>
        <div className="relative w-full">
          <TextInput
            label="Street Address* (PO Box not allowed)"
            name="businessAddress.streetAddress"
            ref={businessAutoCompleteRef as unknown as Ref<HTMLInputElement>}
            value={values?.businessAddress?.streetAddress}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={30}
            error={
              touched?.businessAddress?.streetAddress &&
              Boolean(errors?.businessAddress?.streetAddress)
            }
            errorMessage={errors?.businessAddress?.streetAddress}
            touched={touched?.businessAddress?.streetAddress}
            variant="responsive"
            informationToolTip="A valid business address is required. Any typos or wrong address can lead to lengthened review times."
            color="text-white"
          ></TextInput>
        </div>
        <div className="relative w-full mt-4">
          <TextInput
            label="Street Address 2"
            name="businessAddress.streetDetails"
            value={values?.businessAddress?.streetDetails}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={200}
            error={
              touched?.businessAddress?.streetDetails &&
              Boolean(errors?.businessAddress?.streetDetails)
            }
            errorMessage={errors?.businessAddress?.streetDetails}
            touched={touched?.businessAddress?.streetDetails}
            variant="responsive"
            color="text-white"
          ></TextInput>
        </div>

        <div className="flex flex-col sm:flex-row">
          <div className="relative w-full mt-4">
            <TextInput
              label="City"
              name="businessAddress.city"
              value={values?.businessAddress?.city}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={15}
              error={
                touched?.businessAddress?.city &&
                Boolean(errors?.businessAddress?.city)
              }
              errorMessage={errors?.businessAddress?.city}
              touched={touched?.businessAddress?.city}
              variant="responsive"
              color="text-white"
              required
            ></TextInput>
          </div>
          <div className="relative w-full mt-4 sm:ml-4">
            <Select
              label="State"
              value={values?.businessAddress?.state}
              options={[
                {
                  label: "-- Select a State--",
                  value: "",
                  disabled: true,
                },
              ].concat(
                STATE_CODES.map((m) => {
                  return {
                    label: `${m.label} (${m.value})`,
                    value: m.value,
                    disabled: false,
                  };
                }),
              )}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => setFieldValue("businessAddress.state", e.value)}
              onBlur={() => setFieldTouched("businessAddress.state", true)}
              placeholder="Select"
              touched={touched?.businessAddress?.state}
              error={
                touched?.businessAddress?.state &&
                Boolean(errors?.businessAddress?.state)
              }
              errorMessage={errors?.businessAddress?.state}
              required
              variant="responsive"
              color="text-white"
            ></Select>
          </div>

          <div className="relative w-full mt-4 sm:ml-4">
            <TextInput
              label="Zip"
              name="businessAddress.zip"
              value={values?.businessAddress?.zip}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={5}
              error={
                touched?.businessAddress?.zip &&
                Boolean(errors?.businessAddress?.zip)
              }
              errorMessage={errors?.businessAddress?.zip}
              touched={touched?.businessAddress?.zip}
              variant="responsive"
              color="text-white"
              required
            ></TextInput>
          </div>
        </div>
        <div className="mt-4 text-xs text-white md:text-sm">
          *Required Fields
        </div>

        <div className="my-4 space-y-4" role="group">
          <div className="flex items-center">
            <input
              id="differentDeliveryAddress"
              name="differentDeliveryAddress"
              type="checkbox"
              checked={values.differentDeliveryAddress}
              onChange={handleChange}
              className="w-4 h-4 text-orange-600 border-0 cursor-pointer focus:ring-offset-0 focus:ring-0 focus:outline-none focus:shadow-none"
            />
            <label
              htmlFor="deliveryType1"
              className="block ml-3 text-sm text-white md:text-base"
            >
              Use a different address for card delivery
            </label>
          </div>
        </div>

        <div
          className={classNames(
            !values.differentDeliveryAddress ? "hidden" : "",
            "mb-5",
          )}
        >
          <div className="relative w-full">
            <TextInput
              label="Street Address"
              name="deliveryAddress.streetAddress"
              ref={shippingAutoCompleteRef as unknown as Ref<HTMLInputElement>}
              value={values?.deliveryAddress?.streetAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={30}
              error={
                touched?.deliveryAddress?.streetAddress &&
                Boolean(errors?.deliveryAddress?.streetAddress)
              }
              errorMessage={errors?.deliveryAddress?.streetAddress}
              touched={touched?.deliveryAddress?.streetAddress}
              variant="responsive"
              color="text-white"
              required
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label=" Street Address 2"
              name="deliveryAddress.streetDetails"
              value={values?.deliveryAddress?.streetDetails}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={200}
              error={
                touched?.deliveryAddress?.streetDetails &&
                Boolean(errors?.deliveryAddress?.streetDetails)
              }
              errorMessage={errors?.deliveryAddress?.streetDetails}
              touched={touched?.deliveryAddress?.streetDetails}
              variant="responsive"
              color="text-white"
            ></TextInput>
          </div>

          <div className="flex flex-col sm:flex-row">
            <div className="relative w-full mt-4">
              <TextInput
                label="City"
                name="deliveryAddress.city"
                value={values?.deliveryAddress?.city}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={15}
                error={
                  touched?.deliveryAddress?.city &&
                  Boolean(errors?.deliveryAddress?.city)
                }
                errorMessage={errors?.deliveryAddress?.city}
                touched={touched?.deliveryAddress?.city}
                variant="responsive"
                required
                color="text-white"
              ></TextInput>
            </div>
            <div className="relative w-full mt-4 sm:ml-4">
              <Select
                label="State"
                value={values?.deliveryAddress?.state}
                options={[
                  {
                    label: "-- Select a State--",
                    value: "",
                    disabled: true,
                  },
                ].concat(
                  STATE_CODES.map((m) => {
                    return {
                      label: `${m.label} (${m.value})`,
                      value: m.value,
                      disabled: false,
                    };
                  }),
                )}
                optionLabel="label"
                optionValue="value"
                onChange={(e) =>
                  setFieldValue("deliveryAddress.state", e.value)
                }
                onBlur={() => setFieldTouched("deliveryAddress.state", true)}
                placeholder="Select"
                touched={touched?.deliveryAddress?.state}
                error={
                  touched?.deliveryAddress?.state &&
                  Boolean(errors?.deliveryAddress?.state)
                }
                errorMessage={errors?.deliveryAddress?.state}
                required
                variant="responsive"
                color="text-white"
              ></Select>
            </div>

            <div className="relative w-full mt-4 sm:ml-4">
              <TextInput
                label="Zip"
                name="deliveryAddress.zip"
                value={values?.deliveryAddress?.zip}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={5}
                error={
                  touched?.deliveryAddress?.zip &&
                  Boolean(errors?.deliveryAddress?.zip)
                }
                errorMessage={errors?.deliveryAddress?.zip}
                touched={touched?.deliveryAddress?.zip}
                variant="responsive"
                required
                color="text-white"
              ></TextInput>
            </div>
          </div>
          <div className="mt-4 text-xs text-white md:text-sm">
            *Required Fields
          </div>
        </div>

        <div className="relative w-full">
          <TextInput
            label="Annual Business Revenue (Approximate)"
            name="annualBusinessRevenue"
            value={values.annualBusinessRevenue}
            onChange={(event) =>
              setFieldValue(
                "annualBusinessRevenue",
                fuelFormatter(event?.target?.value),
              )
            }
            onBlur={handleBlur}
            error={
              touched.annualBusinessRevenue &&
              Boolean(errors.annualBusinessRevenue)
            }
            errorMessage={errors.annualBusinessRevenue as string}
            touched={touched.annualBusinessRevenue as boolean}
            variant="responsive"
            color="text-white"
            required
          ></TextInput>
        </div>

        <div className="relative w-full mt-4">
          <TextInput
            label="Total Fuel and Maintenance spend per week"
            name="fuel"
            value={values.fuel}
            onChange={(event) =>
              setFieldValue("fuel", fuelFormatter(event?.target?.value))
            }
            onBlur={handleBlur}
            error={touched.fuel && Boolean(errors.fuel)}
            errorMessage={errors.fuel as string}
            touched={touched.fuel as boolean}
            variant="responsive"
            color="text-white"
            required
          ></TextInput>
        </div>

        <div className="relative w-full mt-4">
          <TextInput
            label="Total Number of Vehicles"
            name="fleetNum"
            value={values.fleetNum}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.fleetNum && Boolean(errors.fleetNum)}
            errorMessage={errors.fleetNum}
            touched={touched.fleetNum}
            variant="responsive"
            color="text-white"
            required
          ></TextInput>
        </div>

        <div className="relative w-full mt-4">
          <Select
            label="Type of Fleet"
            required
            onChange={(e) => setFieldValue("fleetType", e.value)}
            onBlur={() => setFieldTouched("fleetType", true)}
            value={values.fleetType}
            name="fleetType"
            options={[
              {
                label: "-- Select an option --",
                value: "",
                disabled: true,
              },
            ].concat(
              FLEET_TYPES.map((fT) => {
                return {
                  label: fT.label,
                  value: fT.value,
                  disabled: false,
                };
              }),
            )}
            optionLabel="label"
            optionValue="value"
            placeholder="-- Select an option --"
            touched={touched.fleetType}
            error={touched.fleetType && Boolean(errors.fleetType)}
            errorMessage={errors.fleetType}
            color="text-white"
            variant="responsive"
          ></Select>
        </div>

        {/* <div className="mb-5 text-sm text-white ">
          Preferred delivery method
        </div>

        <AddressDeliveryUI
          {...{
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            handleBlur,
            ...rest,
          }}
        /> */}
        <div className="mt-6">
          <Button
            type={ButtonType.Submit}
            variant={ButtonVariant.Orange}
            className="w-full"
            disabled={isSubmitting || isSubmittingOnboardStatus}
            loading={isSubmitting || isSubmittingOnboardStatus}
          >
            <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>
            {dirty ? `Save` : `Next`}
          </Button>
        </div>
        <div>
          <div className="flex items-center justify-center mt-1 text-sm text-center text-white">
            <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
            We securely store and encrypt your personal information
          </div>
          <Button
            type={ButtonType.Button}
            variant={ButtonVariant.Transparent}
            className="pl-0 text-white"
            size={ButtonSize.Small}
            onClick={onPreviousStep}
          >
            &#60;&#60; Go back
          </Button>
        </div>
      </form>
    );
  },
);

export default AdditionalBusinessInfoUI;
