/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { dollarFormatter } from "@roadflex/web-lib";
import { isEmpty } from "lodash";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Skeleton } from "primereact/skeleton";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";

const getDataTransformed = (data: any[], key: string[]) => {
  const transformedData = data?.map((obj) => {
    const newObj = {
      ...obj,
    };
    if (key.length >= 1) {
      newObj[key[0]] = (newObj[key[0]] || 0) / 100;
    }
    if (key.length >= 2) {
      newObj[key[1]] = (newObj[key[1]] || 0) / 100;
    }
    if (key.length >= 3) {
      newObj[key[2]] = (newObj[key[2]] || 0) / 100;
    }
    return newObj;
  });
  return transformedData;
};

const rowTemplate = (
  rowData: { [x: string]: any },
  field: string,
  dataColumnFieldTransform: string | string[],
) => {
  if (dataColumnFieldTransform.includes(field)) {
    return <>{dollarFormatter(rowData[field] || 0)}</>;
  }
  return <>{rowData[field]}</>;
};

export interface GenericDataModalProps {
  title: string;
  openModal: string;
  setOpenModal: (value: string) => void;
  data: any;
  loading: any;
  called: boolean;
  dataColumnName: string[];
  dataColumnField: string[];
  dataColumnFieldTransform: string[];
}

function checkEmpty(systemsValidationData: {
  isLog: any;
  log: any;
  validation: any;
}) {
  if (systemsValidationData?.isLog) {
    return isEmpty(systemsValidationData?.log || {});
  }
  return isEmpty(systemsValidationData?.validation || {});
}

export const GenericDataModal = ({
  title,
  openModal,
  setOpenModal,
  data,
  loading,
  called = false,
  dataColumnName = [],
  dataColumnField = [],
  dataColumnFieldTransform = [],
}: GenericDataModalProps) => {
  let dataTransformed = [];
  if (dataColumnName.length > 0 && dataColumnFieldTransform.length > 0) {
    dataTransformed = getDataTransformed(data || [], dataColumnFieldTransform);
  }
  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-end">
        <Button
          size={ButtonSize.AppSize}
          variant={ButtonVariant.SecondaryOutline}
          onClick={() => setOpenModal("")}
        >
          Close
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      header={title}
      style={{ minWidth: "60vw", maxWidth: "80vw", fontFamily: "Inter" }}
      visible={openModal === "" ? false : true}
      onHide={() => setOpenModal("")}
      closeOnEscape={true}
      closable={false}
      footer={renderFooter}
    >
      {dataColumnName.length > 0 && (
        <div className="shadow sm:rounded-md">
          {called && !loading && (
            <DataTable
              size="small"
              stripedRows
              value={dataTransformed}
              paginator
              rows={10}
              dataKey="id"
              sortField="email"
              sortOrder={1}
              paginatorTemplate={newPaginationTemplate}
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              className="!bg-white w-auto min-w-full custom-table !text-brown-500 !border-brown-500"
              rowsPerPageOptions={[10, 20, 50]}
              responsiveLayout="scroll"
              emptyMessage="No available records"
              style={{ fontFamily: "Inter" }}
            >
              <Column
                filter
                sortable
                filterPlaceholder="Search"
                field={dataColumnField[0]}
                header={dataColumnName[0]}
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={(r) =>
                  rowTemplate(r, dataColumnField[0], dataColumnFieldTransform)
                }
                showFilterMenuOptions={false}
                filterMenuClassName="custom-filter"
              />
              {dataColumnField.length >= 2 && (
                <Column
                  filter
                  sortable
                  filterPlaceholder="Search"
                  field={dataColumnField[1]}
                  header={dataColumnName[1]}
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) =>
                    rowTemplate(r, dataColumnField[1], dataColumnFieldTransform)
                  }
                  showFilterMenuOptions={false}
                  filterMenuClassName="custom-filter"
                />
              )}
              {dataColumnField.length >= 3 && (
                <Column
                  filter
                  sortable
                  filterPlaceholder="Search"
                  field={dataColumnField[2]}
                  header={dataColumnName[2]}
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) =>
                    rowTemplate(r, dataColumnField[2], dataColumnFieldTransform)
                  }
                  showFilterMenuOptions={false}
                  filterMenuClassName="custom-filter"
                />
              )}
              {dataColumnField.length >= 4 && (
                <Column
                  sortable
                  field={dataColumnField[3]}
                  header={dataColumnName[3]}
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) =>
                    rowTemplate(r, dataColumnField[3], dataColumnFieldTransform)
                  }
                />
              )}
              {dataColumnField.length >= 5 && (
                <Column
                  sortable
                  field={dataColumnField[4]}
                  header={dataColumnName[4]}
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) =>
                    rowTemplate(r, dataColumnField[4], dataColumnFieldTransform)
                  }
                />
              )}
              {dataColumnField.length >= 6 && (
                <Column
                  sortable
                  field={dataColumnField[5]}
                  header={dataColumnName[5]}
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) =>
                    rowTemplate(r, dataColumnField[5], dataColumnFieldTransform)
                  }
                />
              )}
              {dataColumnField.length >= 7 && (
                <Column
                  sortable
                  field={dataColumnField[6]}
                  header={dataColumnName[6]}
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) =>
                    rowTemplate(r, dataColumnField[6], dataColumnFieldTransform)
                  }
                />
              )}
              {dataColumnField.length >= 8 && (
                <Column
                  sortable
                  field={dataColumnField[7]}
                  header={dataColumnName[7]}
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(r) =>
                    rowTemplate(r, dataColumnField[7], dataColumnFieldTransform)
                  }
                />
              )}
            </DataTable>
          )}
          {loading && (
            <>
              <Skeleton width="100%" height="3rem" className="mb-2"></Skeleton>
              <Skeleton width="100%" height="3rem" className="mb-2"></Skeleton>
              <Skeleton width="100%" height="3rem" className="mb-2"></Skeleton>
            </>
          )}
        </div>
      )}
      {dataColumnName.length === 0 && checkEmpty(data) && (
        <div>No data present</div>
      )}
      {dataColumnName.length === 0 && !checkEmpty(data) && (
        <div className="mt-4">
          <pre className="leading-3">
            <code className="text-sm">
              {JSON.stringify(
                data?.isLog ? data?.log : data?.validation,
                null,
                4,
              )}
            </code>
          </pre>
        </div>
      )}
    </Dialog>
  );
};
